import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { apiRequest } from "../../../hooks/apiRequest";
import useFilters from "../../../hooks/useFilters";
import { auth } from "../../../utils/auth";
import {
	formatCurrency,
	getCategoriasForDropdown,
	getLink,
	getVendedoresForDropdown,
	getZonasForDropdown,
	isVendedor,
	toast,
} from "../../../utils/helpers";
import { request } from "../../../utils/request";
import Dropdown from "../../general/Dropdown";
import FiltersContainer from "../../general/FiltersContainer";
import Loader from "../../general/Loader";
import Modal from "../../general/Modal";
import PageHeader from "../../general/PageHeader";
import Pagination from "../../general/Pagination";
import { METODOS_PAGO } from "../../../utils/types";
import useLocalStorage from "../../../hooks/useLocalStorage";
import InvalidScreen from "../../general/InvalidScreen";

const estados = [
	{ value: "", label: "Todos" },
	{ value: "completado", label: "Completado" },
	{ value: "cancelado", label: "Cancelado" },
];

const renderEstadoBadge = (estado, factura) => {
	if (estado === "completado") {
		return <span className="badge badge-success p-2">Completado</span>;
	}

	return <span className="badge badge-danger p-2">Cancelado</span>;
};

const RecibosIngreso = () => {
	const role = auth.getUser().rol;
	const [rutasSelected] = useLocalStorage("rutas");
	const { filters, handleFilterChange, filtersQuery } = useFilters({
		buscar: "",
		tipo_pago: "",
		tipo_recibo: role === "edu.caja" || role === "cobrador" || role === "vendedor-cliente" ? "ingreso" : "",
		estado: "",
		vendedor: role === 'cobrador' ? auth.getUser().empleado.id : "",
		zona: rutasSelected instanceof Array
			? rutasSelected.map((v) => v.value).join(",")
			: "",
		desde: isVendedor()
			? dayjs().format("YYYY-MM-DD")
			: dayjs().startOf("month").format("YYYY-MM-DD"),
		hasta: dayjs().format("YYYY-MM-DD"),
	});
	const [recibos, setRecibos] = useState({ data: [] });
	const [isLoading, setIsLoading] = useState(true);
	const [cuentasSeleccionadas, setCuentasSeleccionadas] = useState([]);
	const [showCuentasModal, setShowCuentasModal] = useState(false);
	const [showCancel, setShowCancel] = useState(false);
	const [reciboSeleccionado, setReciboSeleccionado] = useState({});
	const [numeraciones, setNumeraciones] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [tipoRecibos, setTipoRecibos] = useState('todos');

	const [dropdownOptions, setDropdownOptions] = useState({
		zonas: [],
		vendedores: [],
	});

	useEffect(() => {
		if (isVendedor()) {
			setDropdownOptions((state) => ({ ...state, zonas: rutasSelected }));
		} else {
			getZonasForDropdown().then((data) => {
				setDropdownOptions((state) => ({ ...state, zonas: data }));
			});
			getVendedoresForDropdown().then((data) => {
				setDropdownOptions((state) => ({ ...state, vendedores: data }));
			});
		}
	}, []);

	const getRecibosIngreso = async (url) => {
		url = url ? url + "&limit=15" : "recibos-ingreso?limit=15";
		request(url).then((data) => {
			setRecibos(data);
			setIsLoading(false);
		});
	};

    const getNumeraciones = async () => {
        request('numeracion-documentos?tipo_documento=factura_venta').then((response) => {
            setNumeraciones(response.data);
        });
    }

	const toggleCancelModal = (recibo) => {
		setReciboSeleccionado(recibo);
		setShowCancel((state) => !state);
	};

	const cancelRecibo = async (recibo) => {
		const response = await apiRequest(
			null,
			`recibos-ingreso/${recibo}/cancel`,
			"POST"
		);

		if (response.success) {
			toast("Recibo de ingreso cancelado");
			setShowCancel(false);
			getRecibosIngreso(`recibos-ingreso${filtersQuery}`);
		}
	};

    const displayFilters = () => {
        document.getElementById('mas-filtros').classList.toggle('d-none');
    };

	useEffect(() => {
		if (filtersQuery === "") return;
		getRecibosIngreso(`recibos-ingreso${filtersQuery}`);
	}, [filtersQuery]);

    useEffect(() => {
        getNumeraciones();
        getCategoriasForDropdown().then((data) => {
			setCategorias(data);
		});
    }, []);

	if (isVendedor() && !localStorage.getItem("rutas")) {
		return <InvalidScreen message="No hay zonas seleccionadas" />;
	}

	if (auth.is('vendedor-cliente') && !localStorage.getItem("almacenes")) {
		return <InvalidScreen message="No hay almacenes seleccionados" />;
	}

	return (
		<>
			<PageHeader title="Recibos" hasButton={false}>
            <div
					className="col-12 col-md-7 col-lg-2"
					style={{ fontSize: "14px" }}
				>
					<label
						style={{ fontSize: "12px", textTransform: "uppercase" }}
					>
						Tipo de recibo
					</label>
					<Select
						name="tipo_recibo"
						isDisabled={role === "edu.caja" || role === "cobrador" || role === "vendedor-cliente"}
						onChange={(e) => {
							handleFilterChange("tipo_recibo", e.value);
                            setTipoRecibos(e.value);
                        }}
						options={[
							{ value: "", label: "Todos" },
							{ value: "ingreso", label: "Ingreso" },
							{ value: "egreso", label: "Egreso" },
						]}
						styles={{
							control: (styles) => ({
								...styles,
								height: "38px",
								fontSize: "14px",
							}),
						}}
						defaultValue={
							role === "edu.caja" || role === "cobrador" || role === "vendedor-cliente"
								? { value: "ingreso", label: "Ingreso" }
								: { value: "", label: "Todos" }
						}
					/>
				</div>
                <div className="col-12 col-md-7 col-lg-2" style={{ fontSize: "14px" }}>
					<label
						style={{ fontSize: "12px", textTransform: "uppercase" }}
					>
						Numeración
					</label>
                    <Select
                        key="numeracion"
                        name="numeracion"
                        isMulti
                        isClearable
                        onChange={(values) => {
							handleFilterChange("numeracion", values)
						}}
                        options={tipoRecibos === 'egreso' ? [
                            {label: 'Proforma', value: 'compra-proforma'},
                            {label: 'Comprobante Fiscal', value: 'compra-comprobante'},
                        ] : numeraciones.map(numeracion => {
                            return {
                                label: numeracion.nombre,
                                value: numeracion.id,
                            }
                        })}
                    />
				</div>
				<div className="col-12 col-md-7 col-lg-2"
                style={{ fontSize: "14px" }}>
					<label
						style={{ fontSize: "12px", textTransform: "uppercase" }}
					>
						Estado
					</label>
					<Select
						name="estado"
						onChange={(e) =>
							handleFilterChange("estado", e?.value)
						}
                        options={estados.map((estado) => ({
                            label: estado.label,
                            value: estado.value,
                        }))}
					/>
				</div>
				<div className="d-inline-block float-right h-100 d-flex align-items-center">
					<Link
						to={getLink(`/imprimir/recibos-ingreso${filtersQuery}`)}
						target="_blank"
						className="au-btn-filter m-r-15 d-none d-lg-inline-block"
					>
						<i className="zmdi zmdi-print" /> Imprimir
					</Link>
				</div>
			</PageHeader>
			<FiltersContainer>
				<div className="col">
					<label>Buscar</label>
					<input
						type="search"
						placeholder="Buscar..."
						className="form-control"
						name="buscar"
						onChange={(e) =>
							handleFilterChange("buscar", e.target.value)
						}
					/>
				</div>
				{ !auth.is('vendedor-cliente') && <div className="col-12 col-md-6 col-lg-2">
					<label>Vendedor</label>
					<Select
						name="vendedores"
						cacheOptions
						defaultOptions
						isDisabled={role === "cobrador"}
						defaultValue={role === "cobrador" ? {
							value: auth.getUser().empleado.id,
							label: `${auth.getUser().empleado.nombre} ${auth.getUser().empleado.apellido}`,
						} : undefined}
						onChange={(e) =>
							handleFilterChange("vendedor", e.value)
						}
						options={dropdownOptions.vendedores}
					/>
				</div> }
				<div className="col-12 col-md-6 col-lg-2 pr-lg-0">
					<label
						htmlFor="desde"
						style={{ fontSize: "12px", textTransform: "uppercase" }}
					>
						Desde
					</label>
					<input
						type="date"
						name="desde"
						id="desde"
						className="form-control"
						value={filters.desde}
						onChange={(e) =>
							handleFilterChange("desde", e.target.value)
						}
						disabled={isVendedor()}
					/>
				</div>
				<div className="col-12 col-md-6 col-lg-2 pr-lg-0">
					<label
						htmlFor="hasta"
						style={{ fontSize: "12px", textTransform: "uppercase" }}
					>
						Hasta
					</label>
					<input
						type="date"
						name="hasta"
						id="hasta"
						defaultValue={filters.hasta}
						className="form-control"
						onChange={(e) =>
							handleFilterChange("hasta", e.target.value)
						}
						disabled={isVendedor()}
					/>
				</div>
                <div style={{width: '50px'}} className="filtros-avanzados" onClick={displayFilters}>
                    <label>&nbsp;</label>
                    <button className="btn" title="Filtros Avanzados"><i className="zmdi zmdi-filter-list"></i></button>
                </div>
                <div id="mas-filtros" className="row col-md-12 mas-filtros d-none m-b-25 m-t-5">
                <div className="col-12 col-md-6 col-lg-3">
                        <label>Tipo de pago</label>
                        <Select
                            id="tipo_pago"
                            options={[
                                { value: "", label: "Todos" },
                                ...METODOS_PAGO,
                            ]}
                            name="tipo_pago"
                            onChange={(e) =>
                                handleFilterChange("tipo_pago", e.value)
                            }
                            placeholder="Todos"
                            defaultValue={{ value: "", label: "Todos" }}
                            styles={{
                                control: (styles) => ({
                                    ...styles,
                                    height: "38px",
                                }),
                            }}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <label>Zona</label>
                        <Select
                            name="zonas"
                            cacheOptions
                            defaultOptions
                            onChange={(e) => handleFilterChange("zona", e.value)}
                            options={dropdownOptions.zonas}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <label className="orden-filter-label">Categoría Factura</label>
                        <Select
                            id="categoria"
                            options={categorias}
                            name="categoria"
                            isMulti
                            isClearable
                            placeholder="Todas"
                            onChange={(values) =>
                                handleFilterChange("categoria", values)
                            }
                        />
                    </div>
                </div>
			</FiltersContainer>
			<div
				className="row px-3"
				style={{ overflowX: "auto", paddingBottom: "5rem" }}
			>
				{isLoading && <Loader />}
				{!isLoading && recibos.data?.length ? (
					<>
						<RecibosListDesktop
							recibos={recibos}
							setCuentasSeleccionadas={setCuentasSeleccionadas}
							setShowCuentasModal={setShowCuentasModal}
							toggleCancelModal={toggleCancelModal}
							setReciboSeleccionado={setReciboSeleccionado}
						/>
						<RecibosListMobile
							recibos={recibos}
							setCuentasSeleccionadas={setCuentasSeleccionadas}
							setShowCuentasModal={setShowCuentasModal}
							toggleCancelModal={toggleCancelModal}
							setReciboSeleccionado={setReciboSeleccionado}
						/>
					</>
				) : (
					<div>
						{!isLoading && (
							<div>No hay ningún Recibo de ingreso creado.</div>
						)}
					</div>
				)}
				{recibos.links && (
					<Pagination
						links={recibos.links}
						meta={recibos.meta}
						getData={getRecibosIngreso}
					/>
				)}
			</div>
			<Modal
				title="Transacciones procesadas"
				show={showCuentasModal}
				toggle={() => {
					setShowCuentasModal((state) => !state);
				}}
				size="750px"
			>
				<h5 className="text-uppercase text-secondary fw-600 mb-3">
					{reciboSeleccionado?.cliente?.nombre}
				</h5>
				<table className="table table-fixed">
					<tbody>
						<tr>
							<td style={{ fontWeight: "600" }}>NCF/Numero:</td>
							<td style={{ fontWeight: "600" }}>
								Fecha de vencimiento:
							</td>
							<td style={{ fontWeight: "600" }}>Abono:</td>
							<td style={{ fontWeight: "600" }}>Balance:</td>
							<td></td>
						</tr>
						{cuentasSeleccionadas.map((cuenta, i) => (
							<tr key={cuenta.id}>
								<td>
									{reciboSeleccionado.tipo_recibo ===
									"ingreso"
										? cuenta.factura?.numero_comprobante
										: cuenta.compra.ncf}
								</td>
								<td>
									{dayjs(
										reciboSeleccionado.tipo_recibo ===
											"ingreso"
											? cuenta.factura?.fecha_vencimiento
											: cuenta.compra.fecha_vencimiento
									).format("DD/MM/YYYY")}
								</td>
								<td>{formatCurrency(cuenta.abono, "$")}</td>
								<td>{formatCurrency(cuenta.balance, "$")}</td>
								<td>
									{reciboSeleccionado.tipo_recibo ===
									"ingreso" ? (
										<Dropdown
											styles={{
												dropdownMenu: {
													left:
														cuentasSeleccionadas.length -
															1 ===
														i
															? "-160px"
															: "-30%",
													top:
														cuentasSeleccionadas.length -
															1 ===
														i
															? "-20px"
															: "100%",
												},
											}}
										>
											<Link
												to={getLink(
													`/imprimir/facturas/${cuenta.factura?.id}`
												)}
												target="_blank"
												className="dropdown-item"
												title="Impresión normal"
											>
												Impresión
											</Link>
											<Link
												to={getLink(
													`/imprimir/facturas/${cuenta.factura?.id}/doble`
												)}
												target="_blank"
												className="dropdown-item d-none d-sm-inline"
												title="Impresión Doble"
											>
												Impresión doble
											</Link>
										</Dropdown>
									) : (
										<></>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</Modal>
			<Modal
				title="Cancelar Recibo de ingreso"
				show={showCancel}
				callback={() => cancelRecibo(reciboSeleccionado.id)}
				acceptText="Aceptar"
				toggle={toggleCancelModal}
			>
				¿Estás seguro de que deseas cancelar este recibo de ingreso?
				(Esta acción no se puede deshacer).
			</Modal>
		</>
	);
};

const RecibosListDesktop = React.memo(
	({
		recibos,
		setCuentasSeleccionadas,
		setShowCuentasModal,
		toggleCancelModal,
		setReciboSeleccionado,
	}) => {
		return (
			<div
				className="table-data table-responsive table-row__desktop"
				style={{ overflowX: "visible" }}
			>
				<table className="table table-data2">
					<thead>
						<tr>
							<th>Numero</th>
							<th width="300px">Facturas afectadas</th>
							<th>Tipo de recibo</th>
							<th>Contacto</th>
							<th>Creación</th>
							<th>Deuda</th>
							<th>Abono</th>
							<th>Restan</th>
							<th>Tipo de pago</th>
							<th>Estado</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{recibos.data.map((recibo) => (
							<tr className="tr-shadow" key={recibo.id}>
								<td>{recibo.numeracion}</td>
								<td>
									<span>{recibo.cuentas?.map((cuenta) => cuenta.factura?.numero_comprobante ?? cuenta.compra?.ncf ?? cuenta.compra?.numero).join(', ')}</span>
								</td>
								<td className="text-capitalize">
									{recibo.tipo_recibo}
								</td>
								<td>{recibo.cliente?.nombre}</td>
								<td>
									{dayjs(recibo.created_at).format(
										"DD/MM/YYYY"
									)}
								</td>
								<td>{formatCurrency(recibo.deuda, "$")}</td>
								<td>
									{formatCurrency(recibo.abono_total, "$")}
								</td>
								<td>
									{formatCurrency(recibo.balance_total, "$")}
								</td>
								<td className="text-capitalize">
									{recibo.tipo_pago}
								</td>
								<td>{renderEstadoBadge(recibo.status)}</td>
								<td>
									<Dropdown
										styles={{
											dropdownMenu: {
												left: "-30%",
											},
										}}
									>
										<Link
											to={getLink(
												`/imprimir/recibos-ingreso/${recibo.id}`
											)}
											target="_blank"
											className="dropdown-item"
											title="Impresión normal"
										>
											Impresión
										</Link>

										<button
											className="dropdown-item"
											onClick={() => {
												setReciboSeleccionado(recibo);
												setCuentasSeleccionadas(
													recibo.cuentas
												);
												setShowCuentasModal(
													(state) => !state
												);
											}}
										>
											Historial de pagos
										</button>
										{!isVendedor() && (
											<button
												className="dropdown-item"
												onClick={() =>
													toggleCancelModal(recibo)
												}
											>
												Cancelar
											</button>
										)}
									</Dropdown>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	}
);

const RecibosListMobile = React.memo(
	({
		recibos,
		setCuentasSeleccionadas,
		setShowCuentasModal,
		toggleCancelModal,
		setReciboSeleccionado,
	}) => {
		return (
			<div className="table-row__mobile w-100">
				{recibos.data.map((recibo) => (
					<div className="col-12" key={recibo.id}>
						<fieldset
							className="fieldset px-2"
							style={{ border: "2px solid #008aab" }}
						>
							<legend>{recibo.numeracion}</legend>
							<div className="row">
								<div className="form-group col-12 mb-1">
									<label className="orden-filter-label">
										Cliente
									</label>
									<input
										type="text"
										placeholder="Cliente"
										value={recibo.cliente?.nombre}
										className="form-control"
										style={{ backgroundColor: "#fff" }}
										readOnly
									/>
								</div>
							</div>
							<div className="row">
								<div className="form-group col-7 pr-1 mb-1">
									<label className="orden-filter-label">
										Creación
									</label>
									<input
										type="text"
										placeholder="Cliente"
										value={dayjs(recibo.created_at).format(
											"DD/MM/YYYY"
										)}
										style={{ backgroundColor: "#fff" }}
										className="form-control"
										readOnly
									/>
								</div>
								<div className="form-group col-5 pl-1 mb-1">
									<label className="orden-filter-label">
										Deuda
									</label>
									<input
										type="text"
										className="form-control"
										style={{ backgroundColor: "#fff" }}
										value={formatCurrency(
											recibo.deuda,
											"$"
										)}
										readOnly
									/>
								</div>
							</div>
							<div className="row">
								<div className="form-group col-6 pr-1 mb-1">
									<label className="orden-filter-label">
										Abono
									</label>
									<input
										type="text"
										className="form-control"
										style={{ backgroundColor: "#fff" }}
										value={formatCurrency(
											recibo.abono_total,
											"$"
										)}
										readOnly
									/>
								</div>
								<div className="form-group col-6 pl-1 mb-1">
									<label className="orden-filter-label">
										Restan
									</label>
									<input
										type="text"
										className="form-control"
										style={{ backgroundColor: "#fff" }}
										value={formatCurrency(
											recibo.balance_total,
											"$"
										)}
										readOnly
									/>
								</div>
							</div>
							<div className="row">
								<div className="form-group col-6 pr-1 mb-1">
									<label className="orden-filter-label">
										Tipo de pago
									</label>
									<input
										type="text"
										value={recibo.tipo_pago}
										style={{ backgroundColor: "#fff" }}
										className="form-control text-capitalize"
										readOnly
									/>
								</div>
								<div className="form-group col-6 pl-1 mb-1">
									<label className="orden-filter-label">
										<br />
									</label>
									<Dropdown
										classes={{ button: "w-100" }}
										styles={{
											button: {
												height: "38px",
												lineHeight: "0px",
												border: "1px solid #FE9D1F",
											},
											dropdownMenu: {
												zIndex: "99",
											},
										}}
									>
										<Link
											to={getLink(
												`/imprimir/recibos-ingreso/${recibo.id}`
											)}
											target="_blank"
											className="dropdown-item"
											title="Impresión normal"
										>
											Impresión
										</Link>
										<button
											className="dropdown-item"
											onClick={() => {
												setReciboSeleccionado(recibo);
												setCuentasSeleccionadas(
													recibo.cuentas
												);
												setShowCuentasModal(
													(state) => !state
												);
											}}
										>
											Historial de pagos
										</button>
										<button
											className="dropdown-item"
											onClick={() =>
												toggleCancelModal(recibo)
											}
										>
											Cancelar
										</button>
									</Dropdown>
								</div>
							</div>
						</fieldset>
					</div>
				))}
			</div>
		);
	}
);

export default RecibosIngreso;
