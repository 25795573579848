import React, { Component } from "react";
import { auth } from "../../utils/auth";
import { withRequest } from "../../utils/request";
import ImageLoader from "../general/ImageLoader";
import Precios from "./Precios";
import CreatableSelect from "react-select/creatable";
import { apiRequest } from "../../hooks/apiRequest";
import SelectAsync from "react-select/async";
import { getSuplidoresForDropdown, unidades } from "../../utils/helpers";
import ReactSelect from "react-select";

class Form extends Component {
	state = {
		grupos: [],
		almacenes: [],
		inventariable: true,
		cantidad: null,
		precios_venta: [
			{
				id: Date.now(),
				precio_venta: 0,
				impuesto: 0,
				precio_total: 0,
				costo: 0,
			},
		],
		productoType: "producto",
		impuesto_id: "",
		impuestos: [],
		costo_total: 0,
		costo: 0,
		unidades: unidades,
		unidad: unidades[0],
		categoria: null,
		isLoading: false,
		firstTaxSelected: null,
		contacto_id: null,
		lastCostoModified: "costo",
	};

	componentDidMount() {
		this.getTable("grupos");
		this.getTable("almacenes");
		this.getImpuestos();
		this.getUnidades();
		console.log(auth.getUser().id);
		this.setState({
			inventariable: this.props.producto.nombre
				? this.props.producto.inventariable
				: true,
		});
		this.costoField = React.createRef();
	}
	getTable = async (url = null) => {
		const table = await this.props.request(url);

		if (table.data) {
			this.setState({
				[url]: table.data,
				loading: false,
			});
		}
	};
	getImpuestos = async () => {
		const impuestos = await this.props.request("impuestos-dropdown");

		if (impuestos.success) {
			this.setState({
				impuestos: impuestos.data,
				impuesto_id: impuestos.data[0].value,
			});
			return;
		}
	};
	getUnidades = async () => {
		const unidades = await this.props.request("get-unidades");

		if (unidades.success) {
			this.setState((state) => ({
				unidades: [...state.unidades, ...unidades.data],
			}));
			return;
		}
	};
	createUnidad = async (inputValue) => {
		this.setState({ isLoading: true });
		const formData = new FormData();
		formData.append("seccion", "inventario");
		formData.append("nombre", "unidad");
		formData.append("valor", inputValue.toUpperCase());

		const { success, data } = await apiRequest(
			formData,
			"store-config",
			"post"
		);

		if (success) {
			this.setState((state) => ({
				...state,
				unidad: { value: data.valor, label: data.valor },
				isLoading: false,
				unidades: [
					...state.unidades,
					{ value: data.valor, label: data.valor },
				],
			}));
		}
	};
	handleUnidadChange = (newValue) => {
		this.setState({ unidad: newValue });
	};
	onChangeInput = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});

		if (e.target.name === "costo_total") {
			this.setState({ lastCostoModified: "costo_total" });
			this.onChangeCosto(e.target.value);
		} else if (e.target.name === "costo") {
			this.setState({ lastCostoModified: "costo" });
		}

		if (e.target.name !== "costo" && e.target.name !== "impuesto_id")
			return;

		this.onChangeCostoTotal(e.target.name, parseFloat(e.target.value));
	};
	onChangeCheckbox = (e) => {
		this.setState({
			[e.target.name]: e.target.checked,
		});
	};
	onChangeCostoTotal = (field, currentValue) => {
		if (field === "costo") {
			this.setState((state) => ({
				...state,
				costo_total: (
					currentValue +
					currentValue *
						state.impuestos.find(
							(impuesto) =>
								impuesto.value === parseInt(state.impuesto_id)
						).porcentaje
				).toFixed(2),
			}));
			return;
		}
		if (this.state.lastCostoModified === "costo") {
			this.setState((state) => ({
				...state,
				costo_total: (
					parseFloat(state.costo) +
					parseFloat(state.costo) *
						state.impuestos.find(
							(impuesto) => impuesto.value === currentValue
						).porcentaje
				).toFixed(2),
			}));
		} else if (this.state.lastCostoModified === "costo_total") {
			this.setState((state) => ({
				...state,
				costo: (
					state.costo_total /
					(state.impuestos.find(
						(impuesto) =>
							impuesto.value === parseInt(state.impuesto_id)
					).porcentaje +
						1)
				).toFixed(2),
			}));
		}
	};
	onChangeCosto = (currentValue) => {
		this.setState((state) => ({
			...state,
			costo: (
				currentValue /
				(state.impuestos.find(
					(impuesto) => impuesto.value === parseInt(state.impuesto_id)
				).porcentaje +
					1)
			).toFixed(2),
		}));
	};
	addPrecio = () => {
		this.setState((state) => ({
			precios_venta: [
				...state.precios_venta,
				{
					id: Date.now(),
					precio_venta: 0,
					impuesto: "",
					precio_total: 0,
					costo: 0,
				},
			],
		}));
	};

	removePrecio = (producto) => {
		this.setState((state) => ({
			...state,
			precios_venta: state.precios_venta.filter(
				(impuesto) => impuesto.id != producto
			),
		}));
	};
	render() {
		const { producto, errors } = this.props;
		const {
			grupos,
			almacenes,
			inventariable,
			cantidad,
			precios_venta,
			productoType,
			impuesto_id,
			impuestos,
			costo_total,
			costo,
			unidades,
			unidad,
			categoria,
			isLoading,
			// firstTaxSelected,
		} = this.state;
		return (
			<>
				<input type="hidden" name="user_id" value={auth.getUser().id} />
				{/* TIPOS */}
				<div className="row mb-3">
					<div className="col-12 col-sm-6">
						<input
							name="tipo"
							hidden
							value={productoType}
							onChange={() => {}}
						/>
					</div>
				</div>
				<div className="productos">
					<div className="row">
						<div className="col-md-9 row">
							{/* PRIMERA COLUMNA */}
							<div className="col-12 col-md-6">
								<div className="form-group p-t-15 p-b-10">
									<button
										type="button"
										className={`btn btn-lg d-inline-flex col-3 justify-content-between px-2 align-productos-center mr-2 ${
											productoType === "producto"
												? "btn-outline-success"
												: "btn-outline-secondary"
										}`}
										style={{
											maxWidth: "125px",
										}}
										onClick={() =>
											this.setState({
												productoType: "producto",
											})
										}
									>
										PRODUCTO
										<i
											className="fas fa-check-circle"
											style={{
												fontSize: "large",
												display:
													productoType !== "producto"
														? "none"
														: "inline",
											}}
										></i>
									</button>
									<button
										type="button"
										className={`btn btn-lg d-inline-flex col-3 justify-content-between px-2 align-productos-center mr-2 ${
											productoType === "servicio"
												? "btn-outline-success"
												: "btn-outline-secondary"
										}`}
										style={{
											maxWidth: "125px",
										}}
										onClick={() =>
											this.setState({
												productoType: "servicio",
											})
										}
									>
										SERVICIO
										<i
											className="fas fa-check-circle"
											style={{
												fontSize: "large",
												display:
													productoType !== "servicio"
														? "none"
														: "inline",
											}}
										></i>
									</button>
								</div>
								<div className="form-group">
									<label className="label form-control-label">
										Nombre:
									</label>
									<input
										type="text"
										className="text-input form-control"
										id="nombre"
										name="nombre"
										defaultValue={producto.nombre}
									/>
									{errors.nombre && (
										<small className="help-blockParams form-Text">
											{errors.nombre[0]}
										</small>
									)}
								</div>
								<div className="form-group">
									<label className="label form-control-label">
										Referencia/Código:
									</label>
									<input
										type="text"
										className="text-input form-control"
										id="referencia"
										name="referencia"
										defaultValue={producto.referencia}
									/>
									{errors.referencia && (
										<small className="help-blockParams form-Text">
											Este campo es requerido
										</small>
									)}
								</div>
								<div className="form-group">
									<table className="table-precios">
										<tbody>
											<tr>
												<td>
													<label className="label form-control-label">
														Costo:
													</label>
													<input
														type="number"
														className="text-input form-control"
														id="costo"
														name="costo"
														step="0.01"
														value={costo}
														ref={this.costoField}
														onChange={(e) =>
															this.onChangeInput(
																e
															)
														}
													/>
													{errors.costo && (
														<small className="help-blockParams form-Text">
															{errors.costo[0]}
														</small>
													)}
												</td>
												<td className="p-t-15">
													<span className="h2 color-primary">
														+
													</span>
												</td>
												<td width="120px">
													<label className="label form-control-label">
														Impuesto:
													</label>
													<select
														type="text"
														className="form-control"
														id="impuesto_id"
														name="impuesto_id"
														value={impuesto_id}
														onChange={(e) =>
															this.onChangeInput(
																e
															)
														}
													>
														{impuestos.map(
															(impuesto) => (
																<option
																	value={
																		impuesto.value
																	}
																	key={
																		impuesto.value
																	}
																>
																	{
																		impuesto.label
																	}
																</option>
															)
														)}
													</select>
												</td>
												<td className="p-t-15">
													<span className="h2 color-primary">
														=
													</span>
												</td>
												<td>
													<label className="label form-control-label">
														Costo Total:
													</label>
													<input
														type="number"
														className="text-input form-control"
														id="costo_total"
														name="costo_total"
														step="0.01"
														value={costo_total}
														onChange={(e) =>
															this.onChangeInput(
																e
															)
														}
													/>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>

							{/* SEGUNDA COLUMNA */}
							<div className="col-12 col-md-6">
								<div className="form-group row">
									<div
										className="col-md-3"
										style={{ height: "63px" }}
									>
										<label className="label form-control-label">
											Inventariable:
										</label>
										<label className="switch switch-3d switch-success mr-3">
											<input
												type="hidden"
												name="inventariable"
												value="no"
											/>
											<input
												type="checkbox"
												className="switch-input"
												name="inventariable"
												value="yes"
												onChange={this.onChangeCheckbox}
												defaultChecked={
													producto.id
														? producto.inventariable
														: inventariable
												}
											/>
											<span className="switch-label" />
											<span className="switch-handle" />
										</label>
									</div>
									{inventariable ? (
										<div className="col-4">
											<label className="label form-control-label">
												Cantidad:
											</label>
											<input
												type="number"
												className="text-input form-control"
												id="cantidad"
												name="cantidad"
												step="0.01"
												onChange={this.onChangeInput}
												value={
													cantidad
														? cantidad
														: producto.cantidad
												}
											/>
											{errors.cantidad && (
												<small className="help-blockParams form-Text">
													{errors.cantidad[0]}
												</small>
											)}
										</div>
									) : (
										<input
											type="hidden"
											name="cantidad"
											value=""
										/>
									)}
									<div className="col">
										<label className="label form-control-label">
											Unidad:
										</label>
										<CreatableSelect
											options={unidades}
											name="unidad"
											isClearable
											isDisabled={isLoading}
											isLoading={isLoading}
											onChange={this.handleUnidadChange}
											value={unidad}
											onCreateOption={this.createUnidad}
										/>
										{errors.unidad && (
											<small className="help-blockParams form-Text">
												{errors.unidad[0]}
											</small>
										)}
									</div>
								</div>
								{productoType === "producto" && (
									<div className="row">
										<div className="form-group col-6">
											<label className="label form-control-label">
												Estado:
											</label>
											<select
												type="text"
												className="text-input form-control"
												id="estado"
												name="estado"
												defaultValue={producto.estado}
											>
												<option value="nuevo">
													Nuevo
												</option>
												<option value="usado">
													Usado
												</option>
											</select>
											{errors.estado && (
												<small className="help-blockParams form-Text">
													{errors.estado[0]}
												</small>
											)}
										</div>
										<div className="form-group col-6">
											<label className="label form-control-label">
												Reorden:
											</label>
											<input
												type="number"
												name="reorden"
												min="0"
												className="form-control"
												value={producto.reorden}
												onChange={(e) =>
													this.onChangeInput(e)
												}
											/>
											{errors.estado && (
												<small className="help-blockParams form-Text">
													{errors.estado[0]}
												</small>
											)}
										</div>
									</div>
								)}
								{grupos.length > 0 &&
									productoType === "producto" && (
										<div className="row">
											<div className="form-group col-12 col-sm-6">
												<label className="label form-control-label">
													Categoría:
												</label>
												<ReactSelect
													options={grupos.map(
														(grupo) => ({
															value: grupo.id,
															label: grupo.nombre,
														})
													)}
													name="grupo_id"
													isClearable
													isDisabled={isLoading}
													isLoading={isLoading}
													defaultValue={
														producto.grupo
															? producto.grupo.id
															: false
													}
												/>
												{errors.grupo_id && (
													<small className="help-blockParams form-Text">
														{errors.grupo_id[0]}
													</small>
												)}
											</div>
											<div className="form-group col-12 col-sm-6">
												<label className="label form-control-label">
													Almacen:
												</label>
												<select
													className="text-input form-control"
													id="almacen_id"
													name="almacen_id"
													rows="4"
													defaultValue={
														producto.almacen
															? producto.almacen
																	.id
															: false
													}
												>
													<option value="">
														- Seleccionar -
													</option>
													{almacenes
														.filter(
															(a) =>
																a.tipo_producto ===
																"productos"
														)
														.map((almacen) => (
															<option
																key={almacen.id}
																value={
																	almacen.id
																}
															>
																{almacen.nombre}
															</option>
														))}
												</select>
												{errors.almacen_id && (
													<small className="help-blockParams form-Text">
														Este campo es requerido
													</small>
												)}
											</div>
										</div>
									)}
								{productoType === "producto" && (
									<div className="form-group">
										<label className="label form-control-label">
											Suplidor:
										</label>
										<SelectAsync
											name="contacto_id"
											cacheOptions
											defaultOptions
											isClearable
											loadOptions={(e) =>
												getSuplidoresForDropdown(e)
											}
										/>
										{errors.contacto_id && (
											<small className="help-blockParams form-Text">
												Este campo es requerido
											</small>
										)}
									</div>
								)}
							</div>

							{/* PRECIOS */}
							<div className="col-md-12">
								<fieldset className="fieldset">
									<legend>Precios de venta:</legend>
									{precios_venta.map((precio, index) => (
										<Precios
											precio={precio}
											precios={precios_venta}
											index={index}
											key={precio.id}
											costo={this.costoField}
											removePrecio={this.removePrecio}
											impuestos={impuestos}
											rowClasses="w-100 pl-4"
											firstTaxSelected={impuesto_id}
											// setParentState={(e) => this.setState({firstTaxSelected: e})}
											accion="crear"
										/>
									))}
									{errors.precios && (
										<small className="help-blockParams form-Text">
											{errors.precios[0]}
										</small>
									)}
									<div className="text-right">
										<button
											type="button"
											onClick={() => this.addPrecio()}
											className="btn"
										>
											<i className="fas fa-plus" />{" "}
											Agregar Precio
										</button>
									</div>
								</fieldset>
							</div>
						</div>
						<div className="col col-sm-3">
							<div className="form-group">
								<label className="label form-control-label">
									Descripción:
								</label>
								<textarea
									className="text-input form-control"
									id="descripcion"
									name="descripcion"
									rows="4"
									defaultValue={producto.descripcion}
								/>
								{errors.descripcion && (
									<small className="help-blockParams form-Text">
										{errors.descripcion[0]}
									</small>
								)}
							</div>
							{productoType === "producto" && (
								<div className="form-group">
									<label className="label form-control-label">
										Fotos:
									</label>
									<ImageLoader name="fotos[]" multiple />
									{errors.fotos && (
										<small className="help-blockParams form-Text">
											{errors.fotos[0]}
										</small>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withRequest(Form);
