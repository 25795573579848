/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
	formatCurrency,
	getCuentasForDropdown,
	getLink,
	isPastDate,
	isVendedor,
	toast,
	validarPINSeguridad,
} from "../../../utils/helpers";
import FiltersContainer from "../../general/FiltersContainer";
import PageHeader from "../../general/PageHeader";
import Select from "react-select";
import useFilters from "../../../hooks/useFilters";
import dayjs from "dayjs";
import Loader from "../../general/Loader";
import Pagination from "../../general/Pagination";
import { request } from "../../../utils/request";
import Modal from "../../general/Modal";
import PrefixedInput from "../../general/PrefixedInput";
import { apiRequest } from "../../../hooks/apiRequest";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { auth } from "../../../utils/auth";
import Dropdown from "../../general/Dropdown";
import {
	ChequeInfo,
	DepositoInfo,
	OtroInfo,
	TarjetaInfo,
	TransferenciaInfo,
} from "../utils";
import SelectAsync from "react-select/async";
import { METODOS_PAGO } from "../../../utils/types";

// CALCULOS
// al yo hacer los cálculos de abono y pendiente, voy a recorrer las cuentas y al momento de procesar voy a enviar un form con las datas del state
// Puedo hacer los cálculos igual que como están en el <Row /> pero con el total de las cuentas (preferiblemente usar array.reduce()).

// detalles: Debo tener el monto que le voy a abonar a la cuenta

// VALIDACIÓN
// Hay que validar que todo lo que este en ese objeto sea del mismo cliente o que no me pase cuando el objeto no este vació.
// state template = {cliente: number, nombreCliente: string, cuentas: [{id: number, numeracion: string, balance: float, abono: float}], balanceTotal: float, abonoTotal: float}
// desde el componente Row debo enviar en el dispatch: el idCliente, idCuenta(compra), balance (no por cobrar estático) y el abono.
// cuando vaya a hacer el delete de un campo, que busque por idCuenta

// REDUCER ACTIONS
// agregar información al reducer: {cliente: idCliente, cuenta: {id, balance, abono}}
// eliminar cuenta del reducer: {cuenta: id (id compra)}
// Agregar abono: {idCuenta, abono, balance}
// Calcular abono y deuda total: solo se ejecuta el reducer sin payload

const vencimientoOptions = [
	{ value: "todos", label: "Todos" },
	{ value: "no_vencidas", label: "No Vencidas" },
	{ value: "7-", label: "Vencidas 7 días o menos" },
	{ value: "8_15", label: "Vencidas 8 a 15 días" },
	{ value: "16_30", label: "Vencidas 16 días a 30 días" },
	{ value: "31-45", label: "Vencidas 31 días a 45" },
	{ value: "46+", label: "Vencidas mas de 46 días" },
];

const accountsReducer = (state, action) => {
	switch (action.type) {
		case "addAccount":
			if (state.cliente && state.cliente !== action.payload.cliente) {
				return state;
			}
			return {
				...state,
				cliente: action.payload.cliente,
				nombre: action.payload.nombre,
				cuentas: [...state.cuentas, action.payload.cuenta],
			};

		case "deleteAccount":
			const newCuentas = state.cuentas.filter(
				(cuenta) => cuenta.id !== action.payload.cuenta
			);
			return {
				...state,
				cliente: newCuentas.length > 0 ? state.cliente : null,
				cuentas: newCuentas,
			};

		case "changeAbono":
			const cuentaIndex = state.cuentas.findIndex(
				(cuenta) => cuenta.id === action.payload.cuenta
			);
			state.cuentas[cuentaIndex].abono = action.payload.abono;
			state.cuentas[cuentaIndex].balance = action.payload.balance;
			return state;
		case "calcAbonoAndBalance":
			const abonoTotal = state.cuentas.reduce((acc, curr) => {
				return acc + curr.abono;
			}, 0);
			const balanceTotal = state.cuentas.reduce((acc, curr) => {
				return acc + curr.balance;
			}, 0);

			return { ...state, balanceTotal, abonoTotal };
		default:
			return {
				cliente: null,
				nombre: "",
				cuentas: [],
				balanceTotal: 0,
				abonoTotal: 0,
			};
	}
};

const CuentasPorPagar = () => {
	const formRef = useRef();
	const [rutasSelected] = useLocalStorage("rutas");

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);

	const { handleFilterChangeWithoutDebounce, handleFilterChange, filtersQuery, filters } = useFilters({
		buscar: urlParams.get('buscar') ?? "",
		zona: "",
		vendedor: "",
		categoria: "",
		desde: null,
		hasta: dayjs().format("YYYY-MM-DD"),
		vencimiento_desde: "",
		vencimiento_hasta: "",
		zonas_selected:
			rutasSelected instanceof Array
				? rutasSelected.map((v) => v.value).join(",")
				: null,
	});
	const [compras, setFacturas] = useState({ data: [] });
	const [isLoading, setIsLoading] = useState(true);
	const [accountsSelected, setAccountsSelected] = useReducer(
		accountsReducer,
		{
			cliente: null,
			nombre: "",
			cuentas: [],
			balanceTotal: 0,
			abonoTotal: 0,
		}
	);
	const [showProcesarModal, setShowProcesarModal] = useState(false);
	const [pagoInfo, setPagoInfo] = useState({
		pago: 0,
		tipo_pago: "efectivo",
		cuenta: "",
		fecha: dayjs().format("YYYY-MM-DD"),
	});
	const [page, setPage] = useState(1);
	const [saving, setSaving] = useState(false);
	const [vencimientoBtn, setVencimientoBtn] = useState("todos");
	const [totalAdeudado, setTotalAdeudado] = useState(0);
	const [processBtnSelected, setProcessBtnSelected] = useState("normal");

	const [showPINModal, setShowPINModal] = useState(false);
	const [pinSeguridad, setPinSeguridad] = useState("");

	const getCuentasPorPagar = async (url) => {
		url = url ? url + "&limit=15" : "cuentas-por-pagar?limit=15";
		request(url).then((data) => {
			setFacturas(data);
			setIsLoading(false);
		});
	};

	const getTotalAdeudado = (url) => {
		url = url
			? url + "&limit=15"
			: "cuentas-por-pagar/total-adeudado?limit=15";
		request(url).then((data) => {
			setTotalAdeudado(data.data?.totalAdeudado);
			// setIsLoading(false);
		});
	};

	const openProcesarModal = () => {
		if (!accountsSelected.cliente) {
			toast(
				"Debe seleccionar al menos un compra para continuar con el proceso",
				"error"
			);
			return;
		} else if (
			accountsSelected.cuentas.findIndex((cuenta) => cuenta.abono <= 0) >=
			0
		) {
			toast(
				"Debe Introducir monto a abonar en compras seleccionadas para continuar con el proceso",
				"error"
			);
			return;
		}
		setPagoInfo((state) => ({
			...state,
			pago: accountsSelected.abonoTotal,
		}));
		setShowProcesarModal(true);
	};

	const processTransaction = async (e) => {
		e.preventDefault();
		setSaving(true);

		const data = new FormData(e.target);
		const response = await apiRequest(data, "cuentas-por-pagar", "POST");

		if (response.success) {
			if (processBtnSelected === "compra") {
				window.open(
					getLink(
						`/imprimir/compras/${accountsSelected.cuentas[0]?.id}`
					)
				);
			} else if (processBtnSelected === "compra-doble") {
				window.open(
					getLink(
						`/imprimir/compras/${accountsSelected.cuentas[0]?.id}/doble`
					)
				);
			} else {
				toast("Transacción procesada con éxito!");
				window.open(
					getLink(
						`/imprimir/recibos-ingreso/${response.data?.recibo}`
					)
				);
			}
			getCuentasPorPagar(`cuentas-por-pagar${filtersQuery}`);
			getTotalAdeudado(`cuentas-por-pagar/total-adeudado${filtersQuery}`);
			setShowProcesarModal(false);
			setAccountsSelected({ action: "reset" });
			setSaving(false);
			setIsLoading(true);
			setPage(1);
			setPagoInfo({ pago: 0, tipo_pago: "efectivo", cuenta: "", fecha: dayjs().format("YYYY-MM-DD") });
			return;
		}
		toast("Error procesado la transacción", "error");
		setSaving(false);
	};

	const handleVencimientoChange = (dias) => {
		switch (dias) {
			case "todos":
				handleFilterChangeWithoutDebounce("vencimiento_desde", "");
				handleFilterChangeWithoutDebounce("vencimiento_hasta", "");
				setVencimientoBtn("todos");
				break;
			case "no_vencidas":
				handleFilterChangeWithoutDebounce(
					"vencimiento_desde",
					dayjs().format("YYYY-MM-DD")
				);
				handleFilterChangeWithoutDebounce("vencimiento_hasta", "");
				setVencimientoBtn("no_vencidas");
				break;
			case "7-":
				handleFilterChangeWithoutDebounce(
					"vencimiento_desde",
					dayjs().subtract(7, "day").format("YYYY-MM-DD")
				);
				handleFilterChangeWithoutDebounce(
					"vencimiento_hasta",
					dayjs().format("YYYY-MM-DD")
				);
				setVencimientoBtn("7-");
				break;
			case "8_15":
				handleFilterChangeWithoutDebounce(
					"vencimiento_desde",
					dayjs().subtract(15, "day").format("YYYY-MM-DD")
				);
				handleFilterChangeWithoutDebounce(
					"vencimiento_hasta",
					dayjs().subtract(8, "day").format("YYYY-MM-DD")
				);
				setVencimientoBtn("8_15");
				break;
			case "16_30":
				handleFilterChangeWithoutDebounce(
					"vencimiento_desde",
					dayjs().subtract(30, "day").format("YYYY-MM-DD")
				);
				handleFilterChangeWithoutDebounce(
					"vencimiento_hasta",
					dayjs().subtract(16, "day").format("YYYY-MM-DD")
				);
				setVencimientoBtn("16_30");
				break;

			case "31-45":
				handleFilterChangeWithoutDebounce(
					"vencimiento_desde",
					dayjs().subtract(45, "day").format("YYYY-MM-DD")
				);
				handleFilterChangeWithoutDebounce(
					"vencimiento_hasta",
					dayjs().subtract(31, "day").format("YYYY-MM-DD")
				);
				setVencimientoBtn("31-45");
				break;
			default:
				break;
		}
	};

	const getOptions = async (url = null, label = "nombre", value = "id") => {
		const table = await request(url);
		if (table.data) {
			return table.data.map((item) => ({
				value: item[value],
				label: item[label],
			}));
		}
	};

	const checkPINSeguridad = async () => {
		if (await validarPINSeguridad(pinSeguridad)) {
			setPinSeguridad("");
			setShowPINModal(false);
			
			setPage((state) => state + 1);
			return;
		}

		toast("PIN incorrecto, intente de nuevo", "error");
	};

	useEffect(() => {
		if (filtersQuery === "") return;
		getCuentasPorPagar(`cuentas-por-pagar${filtersQuery}`);
		getTotalAdeudado(`cuentas-por-pagar/total-adeudado${filtersQuery}`);
	}, [filtersQuery]);

	return (
		<>
			<PageHeader title="Cuentas por pagar" hasButton={false}>
                <div className="btn-group d-none d-lg-block" role="group">
                    <button
                        type="button"
                        className={`btn btn-secondary ${
                            vencimientoBtn === "todos" ? "active" : ""
                        }`}
                        onClick={() => {
                            handleFilterChangeWithoutDebounce("vencimiento_desde", "");
                            handleFilterChangeWithoutDebounce("vencimiento_hasta", "");
                            setVencimientoBtn("todos");
                        }}
                    >
                        Todos
                    </button>
                    <button
                        type="button"
                        className={`btn btn-secondary ${
                            vencimientoBtn === "no_vencidas" ? "active" : ""
                        }`}
                        onClick={() => {
                            handleFilterChangeWithoutDebounce("vencimiento_desde", dayjs().format("YYYY-MM-DD"));
                            handleFilterChangeWithoutDebounce("vencimiento_hasta", "");
                            setVencimientoBtn("no_vencidas");
                        }}
                    >
                        No vencidas
                    </button>
                    <button
                        type="button"
                        className={`btn btn-secondary ${
                            vencimientoBtn === "7-" ? "active" : ""
                        }`}
                        onClick={() => {
                            handleFilterChangeWithoutDebounce(
                                "vencimiento_desde",
                                dayjs()
                                    .subtract(7, "day")
                                    .format("YYYY-MM-DD")
                            );
                            handleFilterChangeWithoutDebounce(
                                "vencimiento_hasta",
                                dayjs().format("YYYY-MM-DD")
                            );
                            setVencimientoBtn("7-");
                        }}
                    >
                        Vencidas 7 días o menos
                    </button>
                    <button
                        type="button"
                        className={`btn btn-secondary ${
                            vencimientoBtn === "8_15" ? "active" : ""
                        }`}
                        onClick={() => {
                            handleFilterChangeWithoutDebounce(
                                "vencimiento_desde",
                                dayjs()
                                    .subtract(15, "day")
                                    .format("YYYY-MM-DD")
                            );
                            handleFilterChangeWithoutDebounce(
                                "vencimiento_hasta",
                                dayjs()
                                    .subtract(8, "day")
                                    .format("YYYY-MM-DD")
                            );
                            setVencimientoBtn("8_15");
                        }}
                    >
                        Vencidas 8 a 15 días
                    </button>
                    <button
                        type="button"
                        className={`btn btn-secondary ${
                            vencimientoBtn === "16_30" ? "active" : ""
                        }`}
                        onClick={() => {
                            handleFilterChangeWithoutDebounce(
                                "vencimiento_desde",
                                dayjs()
                                    .subtract(30, "day")
                                    .format("YYYY-MM-DD")
                            );
                            handleFilterChangeWithoutDebounce(
                                "vencimiento_hasta",
                                dayjs()
                                    .subtract(16, "day")
                                    .format("YYYY-MM-DD")
                            );
                            setVencimientoBtn("16_30");
                        }}
                    >
                        Vencidas 16 días a 30 días
                    </button>
                    <button
                        type="button"
                        className={`btn btn-secondary ${
                            vencimientoBtn === "31-45" ? "active" : ""
                        }`}
                        onClick={() => {
                            handleFilterChangeWithoutDebounce(
                                "vencimiento_desde",
                                dayjs()
                                    .subtract(45, "day")
                                    .format("YYYY-MM-DD")
                            );
                            handleFilterChangeWithoutDebounce(
                                "vencimiento_hasta",
                                dayjs()
                                    .subtract(31, "day")
                                    .format("YYYY-MM-DD")
                            );
                            setVencimientoBtn("31-45");
                        }}
                    >
                        Vencidas 31 días a 45
                    </button>
                    <button
                        type="button"
                        className={`btn btn-secondary ${
                            vencimientoBtn === "46+" ? "active" : ""
                        }`}
                        onClick={() => {
                            handleFilterChangeWithoutDebounce(
                                "vencimiento_desde",
                                dayjs()
                                    .subtract(10, "year")
                                    .format("YYYY-MM-DD")
                            );
                            handleFilterChangeWithoutDebounce(
                                "vencimiento_hasta",
                                dayjs()
                                    .subtract(46, "day")
                                    .format("YYYY-MM-DD")
                            );
                            setVencimientoBtn("46+");
                        }}
                    >
                        Vencidas mas de 46 días
                    </button>
                </div>
				<div className="d-flex w-100 justify-content-center">
					<div className="col-12 col-md-7 d-block d-lg-none mt-2 font-weight-normal">
						<Select
							options={vencimientoOptions}
							isClearable
							placeholder="Todas"
							onChange={(e) => handleVencimientoChange(e.value)}
							styles={{
								control: (styles) => ({
									...styles,
									height: "45px",
									fontSize: "1.2rem",
								}),
							}}
						/>
					</div>
				</div>
				<div
					className="row w-100 justify-content-center"
					style={{ marginLeft: "0px" }}
				>
					<div className="cuentas-por-cobrar__subheader">
						<div className="w-auto px-3 d-inline-block cuentas-por-cobrar__col">
							{accountsSelected.cliente ? (
								<h3 className="cuentas-por-cobrar__adeudado-title">
									{formatCurrency(
										accountsSelected.abonoTotal +
											accountsSelected.balanceTotal
									)}
								</h3>
							) : (
								<h3 className="cuentas-por-cobrar__adeudado-title">
									{formatCurrency(totalAdeudado)}
								</h3>
							)}
							<span className="cuentas-por-cobrar__adeudado-subtitle">
								TOTAL ADEUDADO
							</span>
						</div>
						{accountsSelected.cliente && (
							<>
								<div className="w-auto px-3 d-inline-block cuentas-por-cobrar__col-2">
									<h3 className="cuentas-por-cobrar__adeudado-title">
										{formatCurrency(
											accountsSelected.abonoTotal
										)}
									</h3>
									<span className="cuentas-por-cobrar__adeudado-subtitle">
										ABONO
									</span>
								</div>
								<div className="w-auto px-3 d-inline-block cuentas-por-cobrar__col-2">
									<h3 className="cuentas-por-cobrar__adeudado-title">
										{formatCurrency(
											accountsSelected.balanceTotal
										)}
									</h3>
									<span className="cuentas-por-cobrar__adeudado-subtitle">
										PENDIENTE
									</span>
								</div>
							</>
						)}
						<div className="d-inline-block float-right h-100 d-flex align-items-center">
							<Dropdown
								btnText="Reportes"
								btnIcon={<i className="zmdi zmdi-print" />}
								classes={{ dropdown: "mr-2" }}
							>
								<Link
									to={getLink(
										`/imprimir/cuentas-por-pagar${filtersQuery}`
									)}
									target="_blank"
									className="dropdown-item"
								>
									Detalles
								</Link>
								<Link
									to={getLink(
										`/imprimir/cuentas-por-pagar-resumen${filtersQuery}`
									)}
									target="_blank"
									className="dropdown-item"
								>
									Resumen
								</Link>
							</Dropdown>
							<button
								className="au-btn au-btn-icon au-btn--green au-btn--small m-r-15"
								onClick={() => openProcesarModal()}
							>
								<i className="zmdi zmdi-plus" />
								Procesar
							</button>
						</div>
					</div>
				</div>
			</PageHeader>
			<FiltersContainer>
				<div className="col-12 col-lg-2">
					<label>Buscar</label>
					<input
						type="search"
						placeholder="Buscar..."
						className="form-control"
						name="buscar"
						defaultValue={filters.buscar}
						onChange={(e) =>
							handleFilterChange("buscar", e.target.value)
						}
					/>
				</div>
				<div className="col-12 col-md-6 col-lg-2">
					<label>Tipo</label>
					<Select
						id="tipo"
						options={[
							{
								label: "Inventario de Productos",
								value: "producto",
							},
							{ label: "Inventario Interno", value: "item" },
							{
								label: "Trabajo/Suministro",
								value: "suministro",
							},
						]}
						name="tipo"
						onChange={(e) => handleFilterChange("tipo", e.value)}
						placeholder="Todos"
						styles={{
							control: (styles) => ({
								...styles,
								height: "38px",
							}),
						}}
					/>
				</div>
                <div className="col-md-2">
                    <label htmlFor="desde">Tipo Factura</label>
                    <Select
                        onChange={(e) =>
                            handleFilterChange("tipo_factura", e?.value)
                        }
                        isClearable
                        options={[
                            {
                                label: "Todas",
                                value: "todas",
                            },
                            {
                                label: "Proforma",
                                value: "proforma",
                            },
                            {
                                label: "Comprobante Fiscal",
                                value: "comprobante",
                            },
                        ]}
                    />
                </div>
				<div className="col-12 col-md-6 col-lg-2">
					<label>Categoría</label>
					<SelectAsync
						name="categorias"
						isMulti
						cacheOptions
						defaultOptions
						onChange={(values) =>
							handleFilterChange(
								"categoria",
								values instanceof Array
									? values.map((v) => v.value).join(",")
									: values
							)
						}
						loadOptions={(e) => {
							return getOptions("taxonomias?relacion=compras&buscar=" + e);
						}}
					/>
				</div>
				<div className="col-12 col-md-6 col-lg-2">
					<label
						htmlFor="desde"
						style={{ fontSize: "12px", textTransform: "uppercase" }}
					>
						Desde
					</label>
					<input
						type="date"
						name="desde"
						id="desde"
						className="form-control"
						onChange={(e) =>
							handleFilterChange("desde", e.target.value)
						}
					/>
				</div>
				<div className="col-12 col-md-6 col-lg-2">
					<label
						htmlFor="hasta"
						style={{ fontSize: "12px", textTransform: "uppercase" }}
					>
						Hasta
					</label>
					<input
						type="date"
						name="hasta"
						id="hasta"
						defaultValue={dayjs().endOf("day").format("YYYY-MM-DD")}
						className="form-control"
						onChange={(e) =>
							handleFilterChange("hasta", e.target.value)
						}
					/>
				</div>
			</FiltersContainer>
			<div className="row pb-4 px-3" style={{ overflowX: "auto" }}>
				{isLoading && <Loader />}
				{!isLoading && compras.data?.length ? (
					<>
						<CuentasListDesktop>
							{compras.data.map((compra) => (
								<RowDesktop
									key={compra.id}
									compra={compra}
									setAccountsSelected={setAccountsSelected}
									clientSelected={accountsSelected.cliente}
									accountsSelected={accountsSelected}
								/>
							))}
						</CuentasListDesktop>
						<div className="table-row__mobile w-100">
							{compras.data.map((compra) => (
								<RowMobile
									key={compra.id}
									compra={compra}
									setAccountsSelected={setAccountsSelected}
									clientSelected={accountsSelected.cliente}
									accountsSelected={accountsSelected}
									openProcesarModal={openProcesarModal}
								/>
							))}
						</div>
					</>
				) : (
					<div>
						{!isLoading && (
							<div>No hay ninguna Factura creada.</div>
						)}
					</div>
				)}
				{compras.links && (
					<Pagination
						links={compras.links}
						meta={compras.meta}
						getData={getCuentasPorPagar}
					/>
				)}
			</div>
			<Modal
				title="Procesar transacción"
				show={showProcesarModal}
				toggle={() => {
					setShowProcesarModal((state) => !state);
				}}
				footer={
					<ModalFooter
						setShowProcesarModal={setShowProcesarModal}
						pago={pagoInfo.pago}
						abono={accountsSelected.abonoTotal}
						page={page}
						setPage={setPage}
						saving={saving}
						formRef={formRef}
						cuentasQty={accountsSelected.cuentas?.length}
						setProcessBtnSelected={setProcessBtnSelected}
						cuentaSelected={pagoInfo.cuenta}
						fecha={pagoInfo.fecha}
						setShowPINModal={setShowPINModal}
					/>
				}
				size="650px"
			>
				<form
					method="post"
					onSubmit={(e) => processTransaction(e)}
					className={`body-transferencia page-${page}`}
					ref={formRef}
				>
					<input
						type="hidden"
						name="contacto_id"
						value={accountsSelected.cliente}
					/>
					<input
						type="hidden"
						name="empleado_id"
						value={isVendedor() ? auth.getUser()?.empleado?.id : ""}
					/>
					<input
						type="hidden"
						name="abono_total"
						value={accountsSelected.abonoTotal}
					/>
					<input
						type="hidden"
						name="balance_total"
						value={accountsSelected.balanceTotal}
					/>
					<div className="transferencia-page py-0">
						<h3
							className="confirmacion-monto"
							style={{ marginBottom: "5px" }}
						>
							{accountsSelected.nombre}
						</h3>
						<div className="row p-b-1 transaccion-detalles-cuenta">
							<div className="col-6">Total a abonar:</div>
							<div className="col-6 text-right">
								<strong>
									{formatCurrency(
										accountsSelected.abonoTotal,
										"$"
									)}
								</strong>
							</div>
						</div>
						<div className="row p-b-20 transaccion-detalles-cuenta">
							<div className="col-6">Balance pendiente:</div>
							<div className="col-6 text-right">
								<strong>
									{formatCurrency(
										accountsSelected.balanceTotal,
										"$"
									)}
								</strong>
							</div>
						</div>

						<div className="form-group">
							<label>Fecha</label>
							<input
								type="date"
								className="form-control"
								id="fecha"
								name="fecha"
								onChange={(e) => {
									const { value } = e.target;

									setPagoInfo((state) => ({
										...state,
										fecha: value,
									}));
								}}
								value={pagoInfo.fecha}
							/>
						</div>

						<div className="form-group">
							<label>Tipo de pago</label>
							<select
								className="form-control"
								name="tipo_pago"
								value={pagoInfo.tipo_pago}
								onChange={(e) => {
									e.persist();
									setPagoInfo((state) => ({
										...state,
										tipo_pago: e.target.value,
									}));
								}}
							>
								{METODOS_PAGO.map((metodo) => (
									<option value={metodo.value}>
										{metodo.label}
									</option>
								))}
							</select>
							<input
								type="hidden"
								name="metodo_pago[tipo_pago]"
								value={pagoInfo.tipo_pago}
							/>
						</div>

						{pagoInfo.tipo_pago === "cheque" && <ChequeInfo />}
						{pagoInfo.tipo_pago === "tarjeta" && <TarjetaInfo />}
						{pagoInfo.tipo_pago === "transferencia" && (<TransferenciaInfo /> )}
						{pagoInfo.tipo_pago === "deposito" && <DepositoInfo />}
						{pagoInfo.tipo_pago === "otro" && <OtroInfo />}

						<div className="form-group">
							<label>Cuenta</label>
							<SelectAsync
								name="cuenta"
								cacheOptions
								defaultOptions
                                menuPosition='fixed'
                                menuPlacement='auto'
								loadOptions={(value) => getCuentasForDropdown(value)}
								placeholder="Seleccione..."
								onChange={(e) => {
									setPagoInfo((state) => ({
										...state,
										cuenta: e,
									}));
								}}
								value={pagoInfo.cuenta}
							/>
							<input
								type="hidden"
								name="metodo_pago[cuenta]"
								value={pagoInfo.cuenta?.value}
							/>
						</div>

						<div className="form-group">
							<label>Monto Recibido</label>
							<PrefixedInput
								type="number"
								prefix="RD$"
								name="monto"
								value={pagoInfo.pago}
								setValue={(e) =>
									setPagoInfo((state) => ({
										...state,
										pago: e,
									}))
								}
							/>
						</div>
						<div className="form-group">
							<label>Comentario</label>
							<textarea
								className="form-control"
								name="notas"
							></textarea>
						</div>
					</div>
					<div className="transferencia-page py-0">
						<h3
							className={`text-center confirmacion-monto ${
								page === 1 ? "d-none" : "d-block"
							}`}
							style={{ marginBottom: "5px" }}
						>
							{accountsSelected.nombre}
						</h3>
						<h3
							className={`text-center ${
								page === 1 ? "d-none" : "d-block"
							}`}
						>
							Confirmación de transacción
						</h3>
						<h4
							className={`text-center confirmacion-monto ${
								page === 1 ? "d-none" : "d-block"
							}`}
						>
							{formatCurrency(accountsSelected.abonoTotal, "$")}
						</h4>
						<div className="table-responsive d-none d-sm-table">
							<table
								className={`table table-fixed ${
									page === 1 ? "d-none" : "d-table"
								}`}
							>
								<tbody>
									<tr>
										<td colSpan={3}>Método de pago:</td>
										<td className="text-capitalize">
											{pagoInfo.tipo_pago}
										</td>
									</tr>
									{pagoInfo.pago -
										accountsSelected.abonoTotal >
										0 && (
										<tr>
											<td colSpan={3}>Devuelta</td>
											<td>
												{formatCurrency(
													pagoInfo.pago -
														accountsSelected.abonoTotal,
													"$"
												)}
											</td>
										</tr>
									)}
									<tr>
										<td style={{ fontWeight: "600" }}>
											NCF/Número:
										</td>
										<td style={{ fontWeight: "600" }}>
											Deuda:
										</td>
										<td style={{ fontWeight: "600" }}>
											Abono:
										</td>
										<td style={{ fontWeight: "600" }}>
											Restan:
										</td>
									</tr>
									{accountsSelected.cuentas.map(
										(cuenta, i) => (
											<tr key={cuenta.id}>
												<td>{cuenta.numeracion}</td>
												<td>
													{formatCurrency(
														cuenta.deuda,
														"$"
													)}
												</td>
												<td>
													{formatCurrency(
														cuenta.abono,
														"$"
													)}
													<input
														type="hidden"
														name={`cuentas[${i}][abono]`}
														value={cuenta.abono}
													/>
												</td>
												<td>
													{formatCurrency(
														cuenta.balance,
														"$"
													)}
													<input
														type="hidden"
														name={`cuentas[${i}][balance]`}
														value={cuenta.balance}
													/>
													<input
														type="hidden"
														name={`cuentas[${i}][id]`}
														value={cuenta.id}
													/>
												</td>
											</tr>
										)
									)}
								</tbody>
							</table>
						</div>
						<div className="d-block d-sm-none">
							<div
								className="row p-2"
								style={{
									borderTop:
										"0.5px solid rgba(137, 137, 137, 0.3)",
									borderBottom:
										"0.5px solid rgba(137, 137, 137, 0.3)",
								}}
							>
								<div className="col-6 px-0">
									Método de pago:
								</div>
								<div className="col-6 text-capitalize">
									{pagoInfo.tipo_pago}
								</div>
							</div>
							{accountsSelected.cuentas.map((cuenta, i) => (
								<div key={cuenta.id}>
									<div
										className="row p-2"
										style={{
											borderTop:
												"0.5px solid rgba(137, 137, 137, 0.3)",
										}}
									>
										<div className="col-6 pl-0 fw-600">
											NCF/Numero <br />
											<span className="font-weight-normal">
												{cuenta.numeracion}
											</span>
										</div>
										<div className="col-6 fw-600">
											Deuda <br />
											<span className="font-weight-normal">
												{formatCurrency(
													cuenta.deuda,
													"$"
												)}
											</span>
										</div>
									</div>
									<div
										className="row p-2"
										style={{
											borderBottom:
												"0.5px solid rgba(137, 137, 137, 0.3)",
										}}
									>
										<div className="col-6 pl-0 fw-600">
											Abono <br />
											<span className="font-weight-normal">
												{formatCurrency(
													cuenta.abono,
													"$"
												)}
											</span>
										</div>
										<div className="col-6 fw-600">
											Restan <br />
											<span className="font-weight-normal">
												{formatCurrency(
													cuenta.balance,
													"$"
												)}
											</span>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</form>
			</Modal>

			{/* <---PIN DE SEGURIDAD MODAL--- */}
			<Modal
				title="PIN de seguridad"
				show={showPINModal}
				callback={() => checkPINSeguridad()}
				acceptText="Aceptar"
				toggle={() => setShowPINModal((state) => !state)}
				size="419px"
			>
				<div className="form-group">
					<label>PIN de seguridad</label>
					<input
						type="password"
						className="form-control"
						id="PIN"
						name="pin_seguridad"
						value={pinSeguridad}
						onChange={(e) =>
							setPinSeguridad(e.target.value)
						}
					/>
					<p
						className="font-weight-bold"
						style={{
							color: "gray",
							fontStyle: "italic",
							lineHeight: "13px",
							fontSize: "0.7rem",
						}}
					>
						Ha seleccionado una fecha pasada.
						Para continuar ingrese PIN de seguridad
					</p>
				</div>
			</Modal>
		</>
	);
};

const CuentasListDesktop = ({ children }) => {
	return (
		<div
			className="table-data table-responsive table-row__desktop"
			style={{ overflowX: "visible" }}
		>
			<table className="table table-data2">
				<thead>
					<tr>
						<th>NCF/Numero</th>
						<th>Suplidor</th>
						<th>Fecha</th>
						<th>Vencimiento</th>
						<th>Total</th>
						<th>Balance</th>
						<th>Abono</th>
						<th className="text-center">Pendiente</th>
					</tr>
				</thead>
				<tbody>{children}</tbody>
			</table>
		</div>
	);
};

const RowMobile = ({
	compra,
	setAccountsSelected,
	clientSelected,
	accountsSelected,
	openProcesarModal,
}) => {
	const [account, setAccount] = useState({
		abono: 0,
		balance: parseFloat(compra.porPagar),
	});

	const [isAccountSelected, setIsAccountSelected] = useState(
		accountsSelected.cuentas.findIndex(
			(cuenta) => cuenta.id === compra.id
		) !== -1
	);

	const handleChangeAbono = (val) => {
		if (parseFloat(val) > compra.porPagar) {
			toast(
				"El monto a abonar no puede exceder el balance de la cuenta",
				"error"
			);
			setAccount({ balance: 0, abono: compra.porPagar });
			setAccountsSelected({
				type: "changeAbono",
				payload: {
					cuenta: compra.id,
					abono: compra.porPagar,
					balance: 0,
				},
			});
			setAccountsSelected({ type: "calcAbonoAndBalance" });
			return;
		}

		const balance = compra.porPagar - parseFloat(val);
		setAccount({
			balance,
			abono: parseFloat(val),
		});
		setAccountsSelected({
			type: "changeAbono",
			payload: {
				cuenta: compra.id,
				abono: parseFloat(val),
				balance,
			},
		});

		setAccountsSelected({ type: "calcAbonoAndBalance" });
	};

	const handleChangeSelected = () => {
		if (clientSelected && clientSelected !== compra.contactoId) return;

		/* if(!isPastDate(compra.fecha)) {
			toast("Esta compra debe trabajarse por el menú de Compras", "error");
			return;
		} */

		if (!!isAccountSelected) {
			setAccount({ balance: compra.porPagar, abono: 0 });
			setAccountsSelected({
				type: "deleteAccount",
				payload: { cuenta: compra.id },
			});
		} else {
			setAccountsSelected({
				type: "addAccount",
				payload: {
					cliente: compra.contactoId,
					nombre: compra.contacto,
					cuenta: {
						id: compra.id,
						numeracion: compra.ncf,
						balance: compra.porPagar,
						abono: 0,
						deuda: compra.porPagar,
					},
				},
			});
		}
		setIsAccountSelected((state) => !state);
		setAccountsSelected({ type: "calcAbonoAndBalance" });
	};

	const evenAbonoAndBalance = () => {
		setAccount({ balance: 0, abono: compra.porPagar });
		setAccountsSelected({
			type: "changeAbono",
			payload: {
				cuenta: compra.id,
				abono: compra.porPagar,
				balance: 0,
				deuda: compra.porPagar,
			},
		});
		setAccountsSelected({ type: "calcAbonoAndBalance" });
	};
	return (
		<div className="col-12">
			<fieldset
				className="fieldset px-2 w-100"
				style={{ border: "2px solid #008aab" }}
			>
				<legend>{compra.ncf}</legend>
				<div className="row">
					<div className="form-group col-2 mb-1">
						<label className="orden-filter-label">
							<br />
						</label>
						<div className="form-check mt-2">
							<input
								className="form-check-input ml-0"
								type="checkbox"
								checked={isAccountSelected}
								onChange={() => handleChangeSelected()}
							/>
						</div>
					</div>

					<div className="form-group col-10 mb-1">
						<label className="orden-filter-label">Suplidor</label>
						<input
							type="text"
							placeholder="Suplidor"
							value={compra.contacto}
							className="form-control"
							style={{ backgroundColor: "#fff" }}
							readOnly
						/>
					</div>
				</div>
				<div className="row">
					<div className="form-group col-7 pr-1 mb-1">
						<label className="orden-filter-label">Fecha</label>
						<input
							type="text"
							placeholder="Cliente"
							value={dayjs(compra.fecha).format("DD/MM/YYYY")}
							style={{ backgroundColor: "#fff" }}
							className="form-control"
							readOnly
						/>
					</div>
					<div className="form-group col-5 pl-1 mb-1">
						<label className="orden-filter-label">
							Fecha emisión
						</label>
						<input
							type="text"
							className="form-control"
							style={{ backgroundColor: "#fff" }}
							value={dayjs(compra.fecha).format("DD/MM/YYYY")}
							readOnly
						/>
					</div>
				</div>
				<div className="row">
					<div className="form-group col-6 pr-1 mb-1">
						<label className="orden-filter-label">
							Vencimiento
						</label>
						<input
							type="text"
							className="form-control"
							style={{ backgroundColor: "#fff" }}
							value={dayjs(compra.fechaVencimiento).format(
								"DD/MM/YYYY"
							)}
							readOnly
						/>
					</div>
					<div className="form-group col-6 pl-1 mb-1">
						<label className="orden-filter-label">Vendedor</label>
						<input
							type="text"
							className="form-control"
							style={{ backgroundColor: "#fff" }}
							value={
								compra.vendedor
									? `${compra.vendedor?.nombre} ${compra.vendedor?.apellido}`
									: ""
							}
							readOnly
						/>
					</div>
				</div>
				<div className="row">
					<div className="form-group col-6 pr-1 mb-1">
						<label className="orden-filter-label">Total</label>
						<input
							type="text"
							placeholder="Cliente"
							value={formatCurrency(compra.total, "$")}
							style={{ backgroundColor: "#fff" }}
							className="form-control"
							readOnly
						/>
					</div>
					<div className="form-group col-6 pl-1 mb-1">
						<label className="orden-filter-label">Balance</label>
						<input
							type="text"
							placeholder="Cliente"
							value={formatCurrency(compra.porPagar, "$")}
							style={{ backgroundColor: "#fff" }}
							className="form-control"
							readOnly
						/>
					</div>
				</div>
				<div className="row">
					<div className="form-group col-6 pr-1 mb-1">
						<label className="orden-filter-label">Abono</label>
						<input
							type="number"
							step="0.01"
							value={account.abono}
							onChange={(e) => handleChangeAbono(e.target.value)}
							className="form-control"
							readOnly={!isAccountSelected}
						/>
					</div>
					<div className="form-group col-6 pl-1 mb-1">
						<label className="orden-filter-label">Pendiente</label>
						<input
							type="text"
							className="form-control"
							style={{ cursor: "pointer" }}
							readOnly
							value={formatCurrency(account.balance, "$")}
							onClick={() =>
								isAccountSelected ? evenAbonoAndBalance() : null
							}
						/>
					</div>
				</div>
				<div className="row mt-2">
					<div className="form-group col-6 pl-1 offset-6 mb-1">
						<Dropdown
							styles={{
								button: {
									height: "38px",
									lineHeight: "0px",
									border: "1px solid #FE9D1F",
								},
								dropdownMenu: {
									zIndex: "99",
								},
							}}
							classes={{ button: "w-100" }}
						>
							<button
								className="dropdown-item"
								onClick={() => openProcesarModal()}
							>
								Procesar
							</button>
							{/* <Link
								to={getLink(`/imprimir/compras/${compra.id}`)}
								target="_blank"
								className="dropdown-item"
								title="Impresión normal"
							>
								Impresión
							</Link>
							<Link
								to={getLink(
									`/imprimir/compras/${compra.id}/doble`
								)}
								target="_blank"
								className="dropdown-item d-none d-sm-block"
								title="Impresión Doble"
							>
								Impresión doble
							</Link> */}
						</Dropdown>
					</div>
				</div>
			</fieldset>
		</div>
	);
};

const RowDesktop = React.memo(
	({ compra, setAccountsSelected, clientSelected, accountsSelected }) => {
		const [account, setAccount] = useState({
			abono: 0,
			balance: parseFloat(compra.porPagar),
		});

		const [isAccountSelected, setIsAccountSelected] = useState(
			accountsSelected.cuentas.findIndex(
				(cuenta) => cuenta.id === compra.id
			) !== -1
		);

		const handleChangeAbono = (val) => {
			if (parseFloat(val) > compra.porPagar) {
				toast(
					"El monto a abonar no puede exceder el balance de la cuenta",
					"error"
				);
				setAccount({ balance: 0, abono: compra.porPagar });
				setAccountsSelected({
					type: "changeAbono",
					payload: {
						cuenta: compra.id,
						abono: compra.porPagar,
						balance: 0,
					},
				});
				setAccountsSelected({ type: "calcAbonoAndBalance" });
				return;
			}

			const balance = compra.porPagar - parseFloat(val);
			setAccount({
				balance,
				abono: parseFloat(val),
			});
			setAccountsSelected({
				type: "changeAbono",
				payload: {
					cuenta: compra.id,
					abono: parseFloat(val),
					balance,
				},
			});

			setAccountsSelected({ type: "calcAbonoAndBalance" });
		};

		const handleChangeSelected = () => {
			if (clientSelected && clientSelected !== compra.contactoId) return;

			/* if(!isPastDate(compra.fecha)) {
				toast("Esta compra debe trabajarse por el menú de Compras", "error");
				return;
			} */

			if (!!isAccountSelected) {
				setAccount({ balance: compra.porPagar, abono: 0 });
				setAccountsSelected({
					type: "deleteAccount",
					payload: { cuenta: compra.id },
				});
			} else {
				setAccountsSelected({
					type: "addAccount",
					payload: {
						cliente: compra.contactoId,
						nombre: compra.contacto,
						cuenta: {
							id: compra.id,
							numeracion: compra.ncf,
							balance: compra.porPagar,
							abono: 0,
							deuda: compra.porPagar,
						},
					},
				});
			}
			setIsAccountSelected((state) => !state);
			setAccountsSelected({ type: "calcAbonoAndBalance" });
		};

		const evenAbonoAndBalance = () => {
			setAccount({ balance: 0, abono: compra.porPagar });
			setAccountsSelected({
				type: "changeAbono",
				payload: {
					cuenta: compra.id,
					abono: compra.porPagar,
					balance: 0,
					deuda: compra.porPagar,
				},
			});
			setAccountsSelected({ type: "calcAbonoAndBalance" });
		};
		return (
			<tr className="tr-shadow">
				<td>
					<div className="form-check">
						<input
							className="form-check-input"
							type="checkbox"
							checked={isAccountSelected}
							onChange={() => handleChangeSelected()}
						/>
						<label
							className="form-check-label"
							htmlFor="defaultCheck1"
						>
							{compra.ncf}
						</label>
					</div>
				</td>
				<td>{compra.contacto}</td>
				<td>{dayjs(compra.fecha).format("DD/MM/YYYY")}</td>
				<td>{dayjs(compra.fechaVencimiento).format("DD/MM/YYYY")}</td>
				<td>{formatCurrency(compra.total, "$")}</td>
				<td>{formatCurrency(compra.porPagar, "$")}</td>
				<td>
					<input
						type="number"
						step="0.01"
						value={account.abono}
						onChange={(e) => handleChangeAbono(e.target.value)}
						className="form-control"
						readOnly={!isAccountSelected}
					/>
				</td>
				<td>
					<input
						type="text"
						className="form-control"
						style={{ cursor: "pointer" }}
						readOnly
						value={formatCurrency(account.balance, "$")}
						onClick={() =>
							isAccountSelected ? evenAbonoAndBalance() : null
						}
					/>
				</td>
				{/* <td>
					<Dropdown
						styles={{
							dropdownMenu: {
								left: "-30%",
							},
						}}
					>
						<Link
							to={getLink(`/imprimir/compras/${compra.id}`)}
							target="_blank"
							className="dropdown-item"
							title="Impresión normal"
						>
							Impresión
						</Link>
						<Link
							to={getLink(`/imprimir/compras/${compra.id}/doble`)}
							target="_blank"
							className="dropdown-item d-none d-sm-block"
							title="Impresión Doble"
						>
							Impresión doble
						</Link>
						<Link
							to={getLink(
								`/consultas/historial-pagos-compras/${compra.id}`
							)}
							className="dropdown-item"
							onClick={() => {
								localStorage.setItem("compra", compra?.ncf);
							}}
						>
							Historial de pagos
						</Link>
					</Dropdown>
				</td> */}
			</tr>
		);
	}
);

const ModalFooter = React.memo(
	({
		setShowProcesarModal,
		pago,
		abono,
		page,
		setPage,
		saving = false,
		formRef,
		cuentasQty,
		setProcessBtnSelected,
		cuentaSelected,
		fecha,
		setShowPINModal
	}) => {
		const prevPage = () => setPage((state) => state - 1);
		const nextPage = () => {
			if (parseFloat(pago) <= 0) {
				toast(
					"El valor del abono no puede ser igual o menor a cero",
					"error"
				);
				return;
			}
			if (abono > pago) {
				toast(
					"El valor del pago no puede ser menor al abono seleccionado",
					"error"
				);
				return;
			}
			if (!cuentaSelected) {
				toast("Elija una cuenta para continuar", "error");
				return;
			}
			if (!fecha) {
				toast("Elija una fecha para continuar", "error");
				return;
			}
			if (isPastDate(fecha)) {
				setShowPINModal(true);
				return;
			}
			setPage((state) => state + 1);
		};
		const submitForm = async (btnClicked) => {
			await setProcessBtnSelected(btnClicked);
			formRef.current.dispatchEvent(
				new Event("submit", { bubbles: true, cancelable: true })
			);
		};

		return (
			<>
				<button
					type="button"
					onClick={() => setShowProcesarModal((state) => !state)}
					className="btn btn-plain"
				>
					Cancelar
				</button>
				{/* {page === 2 && cuentasQty <= 3 && !saving && (
					<button
						className="btn btn-outline-primary mr-2"
						onClick={() => submitForm("compra-doble")}
					>
						Factura Doble
					</button>
				)}
				{page === 2 && cuentasQty <= 3 && !saving && (
					<button
						className="btn btn-outline-primary mr-2"
						onClick={() => submitForm("compra")}
					>
						Factura
					</button>
				)} */}
				{page === 2 && (
					<button
						type="button"
						onClick={prevPage}
						className="btn btn-plain float-left"
					>
						&larr; Atrás
					</button>
				)}
				{page === 1 && (
					<button
						type="button"
						onClick={nextPage}
						className="btn btn-primary"
					>
						Continuar
					</button>
				)}
				{page === 2 &&
					(saving ? (
						<Loader type="small" />
					) : (
						<button
							className="btn btn-outline-primary"
							type="button"
							onClick={() => submitForm("normal")}
						>
							Egreso
						</button>
					))}
			</>
		);
	}
);

export default CuentasPorPagar;
