import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";

import { formatCurrency, formatNumber } from "../../../utils/helpers";

const params = queryString.parse(window.location.search);

const TableOfInventory = ({ categorias }) => {
    return (
        <>
            {Object.keys(categorias).map(categoria => <div className="seccion-producto">
                    <table className='table print-productos m-b-80'>
                        <thead className="head-table-producto">
                            <tr>
                                <th colSpan="4">{categoria === "undefined" ? "SIN CATEGORIZAR" : categoria}</th>
                                <th colSpan="4" className="text-right">Productos: {categorias[categoria].length}</th>
                            </tr>
                            <tr>
                                <th>
                                    Ref/Cód
                                </th>
                                <th>Nombre</th>
                                <th>Almacén</th>
                                <th>Categoría</th>
                                <th className='text-right'></th>
                                {params.costos && (<>
                                <th className='text-right'>Costo</th>
                                <th className='text-right'>Costo Total</th>
                                </>)}
                                <th className='text-right'>Existencias</th>
                                <th className='text-right'>Comentario</th>
                            </tr>
                        </thead>
                        <tbody>
                            {categorias[categoria].map((producto) => {
                                return (
                                    <tr key={producto.id}>
                                        <td>{producto.referencia}</td>
                                        <td>{producto.nombre}</td>
                                        <td>
                                            {producto.almacen && producto.almacen.nombre}
                                        </td>
                                        <td>{producto.grupo && producto.grupo.nombre}</td>
                                        <td className='text-right'>
                                        </td>
                                        {params.costos && (<>
                                        <td className='text-right'>{formatCurrency(producto.costo)}</td>
                                        <td className='text-right'>{formatCurrency(producto.costo_total)}</td>
                                        </>)}
                                        <td className='text-right'>
                                            
                                        </td>
                                        <td className='text-right'>
                                            
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>)}
        </>
    );
};

TableOfInventory.propTypes = {
    categorias: PropTypes.array,
}

export default TableOfInventory;
