/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import SelectAsync from "react-select/async";
import PageHeader from "../../../general/PageHeader";
import dayjs from "dayjs";
import {
	formatCurrency,
	formatDateTime,
	money,
	renderEstadoBadge,
	toast,
} from "../../../../utils/helpers";
import { useData } from "./useData";
import { request } from "../../../../utils/request";
import { NotaCreditoContext } from "../EditNotaCredito";

const Detalles = ({
	form,
	isEdit,
	setFacturaProductosDisponibles,
	setIsFactura,
	triggerResetForm,
	setConDevolucion,
	setEnableProducto,
    setEstaVencida,
}) => {
	// NOTA CREDITO CONTEXT
	const notaCredito = useContext(NotaCreditoContext);

	// FORM
	const [tipoNotaCredito, setTipoNotaCredito] = useState(null);
	const [contacto, setContacto] = useState(null);
	const [factura, setFactura] = useState(null);
	const [facturaSelected, setFacturaSelected] = useState(null);
	const [compra, setCompra] = useState(null);
	const [razon, setRazon] = useState(null);
	const [fecha, setFecha] = useState(dayjs().format("YYYY-MM-DD"));

	const { comprobantes, facturas, contactos, getRazonesForDropdown } = useData(tipoNotaCredito?.value, contacto?.value);

	const [comprobanteValue, setComprobanteValue] = useState("");
	const [comprobanteInfo, setComprobanteInfo] = useState({
		tipo: "",
		numero: "",
	});

	// METHODS
	const handleSelectComprobante = (id) => {
		const currentComprobante = comprobantes.find(
			(comprobante) =>  parseInt(comprobante.value) === parseInt(id)
		);
		if (!currentComprobante) {
			setComprobanteInfo({
				tipo: "",
				numero: "",
			});
			setComprobanteValue("");
			return;
		}

		setComprobanteValue(id);
		setComprobanteInfo({
			tipo: currentComprobante.tipo?.substring(0, 2),
			numero: currentComprobante.numeroSiguiente,
		});

		const restantes = currentComprobante.numeroFinal - currentComprobante.numeroSiguiente + 1;
			if (restantes <= 10) {
				toast(`Usted tiene ${restantes} números disponible`, "error");
			}
	};

	const handleFacturaSelection = (factura) => {
		setFacturaSelected(factura);
        setEstaVencida(false);
		const { value } = factura;
		if (tipoNotaCredito?.value === "compra") {
			request(`compras/${value}`).then((res) => {
                //Si la compra tiene mas de 30 dias
                if (dayjs(res.data.fecha).add(30, 'day') < dayjs()) {
                    setEstaVencida(true);
                }
				setCompra(res.data);
                const cantidades = res.data.notas_credito.filter(n => n.estado !== 'cancelada').reduce((acc, nota) => {
                    nota.productos.forEach(p => {
                        if (p.producto_id) {
                            acc[p.producto_id] = (acc[p.producto_id] || 0) + p.cantidad;
                        }
                        else if (p.item_id) {
                            acc[p.item_id] = (acc[p.item_id] || 0) + p.cantidad;
                        }
                        else if (p.nombre_producto) {
                            acc[p.nombre_producto] = (acc[p.nombre_producto] || 0) + p.cantidad;
                        }
                    });
                    return acc;
                }, {});
				setFacturaProductosDisponibles(res.data?.items.map(p => {
                    if (p.producto_id) {
                        p.cantidad = p.cantidad - (cantidades[p.producto_id] || 0);
                    }
                    else if (p.item_id) {
                        p.cantidad = p.cantidad - (cantidades[p.item_id] || 0);
                    }
                    else if (p.nombre) {
                        p.cantidad = p.cantidad - (cantidades[p.nombre] || 0);
                    }
                    return p;
                }));
			});
			return;
		}

        request(`facturas/${value}`).then((res) => {
            //Si la factura tiene mas de 30 dias
            if (dayjs(res.data.fecha).add(30, 'day') < dayjs()) {
                setEstaVencida(true);
            }
            setFactura(res.data);
            const cantidades = res.data.notas_credito.filter(n => n.estado !== 'cancelada').reduce((acc, nota) => {
                nota.productos.forEach(p => {
                    acc[p.producto_id] = p.cantidad;
                });
                return acc;
            }, {});
            setFacturaProductosDisponibles(res.data?.productos.map(p => {
                p.cantidad = p.cantidad - (cantidades[p.producto_id] || 0);
                return p;
            }));

			const tipos = ["B01", "B02", "B14", "B15", "B16"];

			const label = tipos.includes(res.data?.numero_comprobante.substring(0,3)) ? "Numeración notas crédito (04)" : "Notas de créditos proforma (04)";

			const currentComprobante = comprobantes.find((comprobante) =>  comprobante.label === label);

			handleSelectComprobante(currentComprobante.value);
        });
	};

	const handleChangeContacto = (contacto) => {
		setContacto(contacto);
		setFactura(null);
		setCompra(null);
		setFacturaSelected(null);
		setFacturaProductosDisponibles([]);
	};

	const setEditInfo = () => {
		setContacto({
			value: notaCredito.contacto_id,
			label: notaCredito.contacto?.nombre,
		});
		setRazon({
            value: notaCredito.razon_id,
            label: notaCredito.razon?.detalle,
            tiene_devolucion: notaCredito.razon?.tiene_devolucion,
        });
		setEnableProducto(true);
		setComprobanteInfo(notaCredito.numeracion);
		setFecha(dayjs(notaCredito.fecha).format("YYYY-MM-DD"));
		setTipoNotaCredito({
			value: notaCredito.tipo,
			label: notaCredito.tipo,
		});
		if (notaCredito.tipo === "venta") {
			setFactura(notaCredito.factura);
			setFacturaSelected({
				value: notaCredito.factura_id,
				label: notaCredito.factura?.numero_comprobante,
				fecha: notaCredito.factura?.fecha,
				total: notaCredito.factura?.total,
				estado: notaCredito.factura?.estado,
			});
			return;
		}
		setCompra(notaCredito.compra);
		setFacturaSelected({
			value: notaCredito.compra_id,
			label: notaCredito.compra?.ncf || notaCredito.compra?.numero,
			fecha: notaCredito.compra?.fecha,
			total: notaCredito.compra?.total,
			estado: notaCredito.compra?.estado,
		});
	};

	const resetFields = () => {
		setContacto(null);
		setRazon(null);
		setEnableProducto(isEdit);
		setComprobanteInfo(null);
		setFecha(dayjs().format("YYYY-MM-DD"));
		setTipoNotaCredito(null);
		setCompra(null);
		setFacturaSelected(null);
		setFactura(null);
	};

	const facturaLabel = useCallback((factura) => {
		return (
			<span
				className="row px-0 align-items-center"
				style={{ width: "inherit" }}
			>
				<span
					className="col-3 pr-1"
					style={{ minWidth: "100px", wordBreak: "break-all" }}
				>
					{dayjs(factura.fecha).format("DD/MM/YYYY")}
				</span>
				<span className="col-3 px-1">{factura.label}</span>
				<span
					className="col-3 pr-0"
					style={{
						fontWeight: "bold",
						color: "#008aab",
					}}
				>
					{formatCurrency(money(factura.total) || 0, "$")}
				</span>
				<span className="col-2 px-1">
					{renderEstadoBadge(factura.estado)}
				</span>
			</span>
		);
	}, []);

	const razonLabel = useCallback((razon) => {
		return (
			<span
				className="row px-2 align-items-center"
				style={{ width: "inherit" }}
			>
				<span className="col-7">{razon.label}</span>
				<span className="col-1">-</span>
				{razon.tiene_devolucion === "Si" ? "Con devolución" : "Sin devolución"}

			</span>
		);
	}, []);

	const renderEstadoBadge = (estado) => {
		if (estado === "pendiente") {
			return <span className="badge badge-secondary p-2">Pendiente</span>;
		} else if (estado === "pagada") {
			return <span className="badge badge-success p-2">Pagada</span>;
		}

		return <span className="badge badge-danger p-2">Cancelada</span>;
	};

	useEffect(() => {
		setIsFactura(tipoNotaCredito?.value === "venta");
		if (isEdit) return;
		if (!tipoNotaCredito || tipoNotaCredito?.value === "compra") {
			setComprobanteInfo("");
		} else {
			setComprobanteInfo({
				tipo: "",
				numero: "",
			});
		}

		setFactura(null);
		setCompra(null);
		setContacto(null);
		setFacturaSelected(null);
	}, [tipoNotaCredito]);

	useEffect(() => {
		if (!isEdit || !notaCredito?.id) return;
		setEditInfo();
	}, [notaCredito]);

	useEffect(() => {
		if (triggerResetForm) {
			resetFields();
		}
	}, [triggerResetForm]);

	return (
		<>
			{!isEdit && (
				<PageHeader title="Nueva Nota de Crédito" hasButton={false}>
					{tipoNotaCredito?.value === "venta" ? (
						<div className="col-7 col-sm-4 offsett-8">
							<div
								className="form-group"
								style={{ marginBottom: "0.3rem" }}
							>
								<div className="pl-0">
									<select
										name="numeracion_documento_id"
										id="comprobante"
										value={comprobanteValue}
										onChange={(e) =>
											handleSelectComprobante(
												e.target.value
											)
										}
									>
										<option value="">
											Seleccione Comprobante
										</option>
										{comprobantes?.map((comprobante) => (
											<option
												value={comprobante.value}
												key={comprobante.value}
											>
												{comprobante.label}
											</option>
										))}
									</select>
									{form.errors.numeracion_documento_id && (
										<small className="help-blockParams form-Text">
											{
												form.errors
													.numeracion_documento_id[0]
											}
										</small>
									)}
								</div>
							</div>
						</div>
					) : (
						<input
							type="hidden"
							// name="numeracion_documento_id"
							value="randomID"
						/>
					)}
				</PageHeader>
			)}
			{isEdit && (
				<PageHeader title="Editar Nota de Credito" hasButton={false} />
			)}

			<div className="row">
				{/* PRIMERA COLUMNA */}
				<div className="col-12 col-lg-4 d-inline-block">
					<div
						className="form-group"
						style={{ marginBottom: "0.3rem" }}
					>
						<label htmlFor="inputTipo" className="">
							Tipo de nota de crédito
						</label>
						<Select
							id="inputTipo"
							options={[
								{ value: "compra", label: "Compra" },
								{ value: "venta", label: "Venta" },
							]}
							name="tipo"
							onChange={(e) => setTipoNotaCredito(e)}
							value={tipoNotaCredito}
							styles={{
								control: (styles) => ({
									...styles,
									height: "38px",
                                    textTransform: 'capitalize'
								}),
							}}
							isDisabled={isEdit}
						/>
						{form.errors?.tipo && (
							<small className="help-blockParams form-Text">
								{form.errors?.tipo[0]}
							</small>
						)}
					</div>
					<div
						className={`form-group`}
						style={{ marginBottom: "0.3rem" }}
					>
						<label htmlFor="inputContacto">Contacto</label>
						<Select
							id="inputContacto"
							name="contacto_id"
							onChange={(e) => handleChangeContacto(e)}
							styles={{
								control: (styles) => ({
									...styles,
									height: "38px",
								}),
							}}
							options={contactos}
							isDisabled={isEdit || !tipoNotaCredito}
							value={contacto}
						/>
						{form.errors?.contacto_id && (
							<small className="help-blockParams form-Text">
								{form.errors?.contacto_id[0]}
							</small>
						)}
					</div>
				</div>

				{/* SEGUNDA COLUMNA */}
				<div className="col-12 col-lg-5 d-inline-block">
					<div
						className="form-group"
						style={{ marginBottom: "0.3rem" }}
					>
						<label htmlFor="inputFactura" className="">
							Factura
						</label>
						<Select
							id="inputFactura"
							name={
								tipoNotaCredito?.value === "venta"
									? "factura_id"
									: "compra_id"
							}
							onChange={(e) => handleFacturaSelection(e)}
							styles={{
								control: (styles) => ({
									...styles,
									height: "38px",
								}),
								singleValue: (provided, state) => ({
									...provided,
									width: "100%",
									display: "flex",
								}),
								option: (provided, state) => ({
									...provided,
									backgroundColor: state.isSelected
										? "rgba(152, 204, 245,.3)"
										: "white",
									color: state.isSelected
										? "#008aab"
										: "#666666",
									fontWeight: state.isSelected
										? "600"
										: "normal",
								}),
							}}
							options={facturas}
							isDisabled={isEdit || !contacto}
							value={facturaSelected}
							formatOptionLabel={(option) => facturaLabel(option)}
						/>
						{form.errors?.factura_id && (
							<small className="help-blockParams form-Text">
								{form.errors?.factura_id[0]}
							</small>
						)}
					</div>
					<div
						className={`form-group`}
						style={{ marginBottom: "0.3rem" }}
					>
						<label htmlFor="inputRazon">Razón</label>
						<SelectAsync
							id="inputRazon"
							name="razon_id"
							styles={{
								control: (styles) => ({
									...styles,
									height: "38px",
								}),
								singleValue: (provided, state) => ({
									...provided,
									width: "100%",
									display: "flex",
								}),
							}}
							cacheOptions
							defaultOptions
							value={razon}
							loadOptions={getRazonesForDropdown}
							isDisabled={!factura && !compra}
							onChange={(e) => {
								if(e.tiene_devolucion === "Si") {
									setConDevolucion(true);
								} else {
									setConDevolucion(false);
								}
								setRazon(e);
								setEnableProducto(e ? true: false);
							}}
							formatOptionLabel={(option) => razonLabel(option)}
						/>
						<input
							type="hidden"
							value={razon?.label}
							name="razon"
						/>
						{form.errors?.razon && (
							<small className="help-blockParams form-Text">
								{form.errors?.razon[0]}
							</small>
						)}
					</div>
				</div>

				{/* TERCERA COLUMNA */}
				<div className="col-12 col-lg-3 d-inline-block">
					{!isEdit && tipoNotaCredito?.value === "venta" ? (
						<div
							className="form-group"
							style={{ marginBottom: "0.3rem" }}
						>
							<label>No.</label>
							<div className="row">
								<div className="col-3 pr-2">
									<input
										type="text"
										className="form-control"
										value={comprobanteInfo.tipo}
										readOnly
									/>
								</div>
								<div className="col-9 pl-0">
									<input
										type="text"
										className="form-control"
										value={comprobanteInfo.numero}
										readOnly
									/>
									{comprobanteInfo?.tipo && (
										<input
											type="hidden"
											name="numeracion"
											value={`${comprobanteInfo.tipo}${comprobanteInfo.numero}`}
										/>
									)}
								</div>
							</div>
							{form.errors?.numeracion && (
								<small className="help-blockParams form-Text">
									{form.errors?.numeracion[0]}
								</small>
							)}
						</div>
					) : (
						<div className="row">
							<div
								className="form-group col-12"
								style={{ marginBottom: "0.3rem" }}
							>
								<label>NO.</label>
								<input
									type="text"
									name="numeracion"
									className="form-control"
									value={comprobanteInfo}
									onChange={(e) =>
										setComprobanteInfo(e.target.value)
									}
									readOnly={isEdit}
								/>
								{form.errors?.numeracion && (
									<small className="help-blockParams form-Text">
										{form.errors?.numeracion[0]}
									</small>
								)}
							</div>
						</div>
					)}

					<div
						className="form-group"
						style={{ marginBottom: "0.3rem" }}
					>
						<label htmlFor="fecha">Fecha</label>
						<input
							type="date"
							className="form-control"
							id="fecha"
							name="fecha"
							onChange={(e) =>
								setFecha(
									dayjs(e.target.value).format("YYYY-MM-DD")
								)
							}
							value={fecha}
						/>
						{form.errors?.fecha && (
							<small className="help-blockParams form-Text">
								{form.errors?.fecha[0]}
							</small>
						)}
					</div>
				</div>
			</div>
			<hr />

			{/* TABLA  FACTURA */}
			<FacturaTable factura={factura} compra={compra} />
		</>
	);
};

Detalles.propTypes = {
	dropdownOptions: PropTypes.object,
	contactoInfo: PropTypes.object,
	form: PropTypes.object,
	handleInputChange: PropTypes.func,
	getComprobanteData: PropTypes.func,
	aplicarLimiteFecha: PropTypes.func,
	handleDetalleChange: PropTypes.func,
	clientLabel: PropTypes.func,
	isEdit: PropTypes.bool,
};

export default React.memo(Detalles);

const FacturaTable = ({ factura, compra }) => {
	return (
		<div className="row px-3">
			{factura && (
				<div
					className="table-data table-responsive table-row__desktop"
					style={{ overflowX: "visible" }}
				>
					<table className="table table-data2" style={{ minHeight: '100px' }}>
						<thead>
							<tr>
								<th>NCF/Numero</th>
								<th>Creación</th>
								<th>Fecha</th>
								<th>Vencimiento</th>
								<th>Total</th>
								{/* <th>Balance</th> */}
								<th>Vendedor</th>
								<th>Zona</th>
								<th className="text-center">Estado</th>
							</tr>
						</thead>
						<tbody>
							{!factura ? (
								<tr>
									<td colSpan="9">
										No hay factura seleccionada
									</td>
								</tr>
							) : (
								<tr className="tr-shadow margenes-reducidos-table" key={factura.id}>
									<td>{factura.numero_comprobante}</td>
									<td>
										{formatDateTime(factura.created_at)}
									</td>
									<td>
										<div>
											{dayjs(factura.fecha).format(
												"DD/MM/YYYY"
											)}
										</div>
										<div
											className={`${
												!!factura.recurrencia_id &&
												factura.validada === "si"
													? "d-block"
													: "d-none"
											}`}
										>
											<span className="fw-600">
												Emisión:{" "}
											</span>
											{dayjs(
												factura.fecha_emision
											).format("DD/MM/YYYY")}
										</div>
									</td>
									<td>
										{dayjs(
											factura.fecha_vencimiento
										).format("DD/MM/YYYY")}
									</td>
									<td>
										{formatCurrency(factura.total, "$")}
									</td>
									{/* <td>
										{formatCurrency(
											factura.por_cobrar,
											"$"
										)}
									</td> */}
									<td>
										{factura.vendedor
											? `${factura.vendedor?.nombre} ${factura.vendedor?.apellido}`
											: ""}
									</td>
									<td>{factura.ruta?.nombre}</td>
									<td className="text-center">
										{renderEstadoBadge(
											factura.estado,
											factura.id
										)}
										<span
											className={`text-success ${
												factura.estado ===
													"pendiente" &&
												factura.recurrencia_id &&
												factura.validada === "si"
													? "d-block"
													: "d-none"
											}`}
										>
											Validada
										</span>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			)}

			{compra && (
				<div
					className="table-data table-responsive table-row__desktop"
					style={{ overflowX: "visible" }}
				>
					<table className="table table-data2" style={{ minHeight: '100px' }}>
						<thead>
							<tr>
								<th>Fecha</th>
								<th>Numero</th>
								<th>NCF</th>
								<th>Total</th>
								<th>Tipo</th>
								<th className="text-center">Estado</th>
							</tr>
						</thead>
						<tbody>
							{!compra ? (
								<tr>
									<td colSpan="9">
										No hay compra seleccionada
									</td>
								</tr>
							) : (
								<tr className="tr-shadow" key={compra.id}>
									<td>
										{dayjs(compra.fecha).format(
											"DD/MM/YYYY"
										)}
									</td>
									<td>{compra.numero}</td>
									<td>{compra.ncf}</td>
									<td>{formatCurrency(compra.total, "$")}</td>
									<td>{compra.tipo}</td>
									<td className="text-center">
										{renderEstadoBadge(compra.estado)}
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			)}
		</div>
	);
};
