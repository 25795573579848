import React from 'react';
import { getLink, isVendedor } from "../../../utils/helpers";
import { Link } from 'react-router-dom';

export const calcularTotalImpuestos = (productos) => {
    return productos?.reduce((accum, producto) => {
        return accum + parseFloat(producto.impuestos);
    }, 0);
};

export const calcularImpuestos = (productos) => {
    let impuestos = {};
    if (productos) {
        productos.forEach((producto) => {
            const nombreImpuesto = `${tipos_impuesto[producto.impuesto?.tipo]} ${
                producto.impuesto?.porcentaje * 100
            }%`;

            if (!tipos_impuesto[producto.impuesto?.tipo] || producto.impuesto?.tipo === 'exento') return;
            if ( isNaN(impuestos[nombreImpuesto]) ) {
                impuestos[nombreImpuesto] = 0;
            }

            impuestos[nombreImpuesto] += ((producto.total / producto.cantidad) - producto.importe) * producto.cantidad;
        });
    }

    Object.keys(impuestos).forEach(impuesto => {
        impuestos[impuesto] = impuestos[impuesto].toFixed(2);
    });

    return impuestos;
};

export const calcularExentoGrabado = (productos) => {
    let exentoGrabado = {};
    if (productos) {
        productos.forEach((producto) => {
            const nombreImpuesto = `${tipos_impuesto[producto.impuesto?.tipo]} ${
                producto.impuesto?.porcentaje * 100
            }%`;
            const nombre = producto.impuesto?.tipo === 'exento' ? 'Exento' : 'Grabado';
            if ( isNaN(exentoGrabado[nombre]) ) {
                exentoGrabado[nombre] = 0;
            }
            if ( isNaN(exentoGrabado[nombreImpuesto]) ) {
                exentoGrabado[nombreImpuesto] = 0;
            }

            exentoGrabado[nombreImpuesto] += parseFloat(producto.subtotal);
            exentoGrabado[nombre] += parseFloat(producto.subtotal);
        });
    }

    return exentoGrabado;
};

export const calcularSubtotal = (productos) => {
    const result = productos?.reduce((accum, producto) => {
        return accum + parseFloat(producto.subtotal);
    }, 0);
    return result > 0 ? result?.toFixed(2) : 0;
};

export const calcularDescuento = (productos) => {
    const result = productos?.reduce((accum, producto) => {
        return accum + parseFloat(producto.descuento);
    }, 0)
    return result > 0 ? result?.toFixed(2) : 0;
};

export const calcularTotal = (productos) => {
    const result = productos?.reduce((accum, producto) => {
        return accum + parseFloat(producto.total);
    }, 0);
    return result > 0 ? result?.toFixed(2) : 0;
};

export const tipos_impuesto = {
	itbis: "ITBIS",
	otro: "Otro",
	isc: "ISC",
	propina_legal: "Propina",
	no_facturable: "No facturable",
	exento: "Exento",
};

export const templateProducto = (id) => ({
    id: id,
    subtotal: 0,
    impuestos: 0,
    total: 0,
    cantidad: 1,
    impuesto: {},
});

export const clientLabel = (cliente) => {
    return (
        <span
            className="row px-0 align-items-center"
            style={{ width: "-webkit-fill-available" }}
        >
            <span
                className="col-10"
                style={{ minWidth: "100px", wordBreak: "break-all", overflowX: 'hidden' }}
            >
                {cliente.label}
            </span>
            {!isVendedor() && (
                <Link
                    to={getLink(
                        `/contactos/${cliente.value}/transacciones`
                    )}
                    className="producto color-primary col-2 px-2"
                    target="_blank"
                    onClick={() => {
                        localStorage.setItem("contacto", JSON.stringify({id: cliente.value, nombre: cliente.label}));
                    }}
                >
                    <i className="fas fa-eye ml-0 mr-auto"></i>
                </Link>
            )}
        </span>
    );
};
