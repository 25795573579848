/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";

import { formatCurrency, getOptionsForDropdown, money } from "../../../../utils/helpers";
import { NotaCreditoContext } from "../EditNotaCredito";
import ProductoFacturaNotaCredito from "./ProductoFacturaNotaCredito";
import ProductoCompraNotaCredito from "./ProductoCompraNotaCredito";

const tipos_impuesto = {
	itbis: "ITBIS",
	otro: "Otro",
	isc: "ISC",
	propina_legal: "Propina",
	no_facturable: "No facturable",
	exento: "Exento",
};

const ProductosContainer = ({
	facturaProductosDisponibles = [],
	form = { errors: {} },
	isEdit,
	isFactura,
	triggerResetForm,
	conDevolucion,
	enableProducto,
    estaVencida,
}) => {
	// NOTA DE CREDITO CONTEXT
	const notaCredito = useContext(NotaCreditoContext);

	const [sumatoriaFinal, setSumatoriaFinal] = useState({
		subtotal: 0,
		total: 0,
		impuestos: notaCredito?.total_impuestos || {},
		descuento: 0,
	});
	const [productosSelected, setProductosSelected] = useState({
		[Date.now()]: {
			id: Date.now(),
			productoID: null,
			nombre: false,
			cantidad: 1,
			costo: 0,
			descuento: 0,
			precios: [],
			impuesto: null,
			precioVenta: 0,
			existencia: 0,
			unidad: null,
			cantidadAnterior: 0,
			precioCompra: 0,
			descuentoCalculado: 0,
			impuestoCalculado: 0,
		},
	});
	const [totalManual, setTotalManual] = useState(0);
	const [totalImpuestos, setTotalImpuestos] = useState(0);
	const [subtotal, setSubtotal] = useState(0);
	const [impuestoManual, setImpuestoManual] = useState(0);
	const [impuestos, setImpuestos] = useState([]);

	const updateTotalSummary = () => {
		let subtotal = 0;
		let total = 0;
		let descuento = 0;
		let impuestos = {};

		Object.values(productosSelected).forEach((producto) => {
			subtotal += (producto.total - producto.impuestoCalculado) || 0;
			total += estaVencida ? subtotal : producto.total || 0;
			descuento += producto.descuentoCalculado;

			// IMPUESTOS
			if (producto.impuesto && producto.impuestoCalculado > 0) {
                let nombre = `${tipos_impuesto[producto.impuesto.tipo]} ${
                    producto.impuesto.porcentaje * 100
                }%`;

                if (typeof impuestos[nombre] !== 'undefined') {
                    impuestos[nombre] += estaVencida ? 0 : producto.impuestoCalculado || 0;
                } else {
                    impuestos[nombre] = estaVencida ? 0 : producto.impuestoCalculado || 0;
                }
			}
		});

		total -= descuento;

		setSumatoriaFinal({
			subtotal,
			total,
			impuestos,
			descuento,
		});
	};

	const handleInputChange = (field, value, key, updateSingleField = true) => {
		if (updateSingleField) {
			setProductosSelected((state) => ({
				...state,
				[key]: { ...state[key], [field]: value },
			}));
			return;
		}
		setProductosSelected((state) => ({
			...state,
			[key]: value,
		}));
	};

	const addProducto = () => {
		const productKey = Date.now();
		setProductosSelected((prevState) => ({
			...prevState,
			[productKey]: {
				id: productKey,
				productoID: null,
				nombre: false,
				cantidad: 1,
				costo: 0,
				descuento: 0,
				precios: [],
				impuesto: null,
				precio_venta: null,
				existencia: null,
				unidad: null,
				cantidadAnterior: null,
				precioCompra: null,
			},
		}));
	};

	// El productoID hace referencia al ID del producto en tabla <productos>
	// El index hace referencia al indice del producto en el form
	// El id hace referencia al id en tabla <notas_credito_productos>
	// en la creacion no tienen un ID en BD asi que hay uno random.
	const checkProductoRepetido = (productoID, index, key) => {
		const productoRepetido = Object.values(productosSelected).find(
			(producto) =>
				producto.productoID == productoID
		);

		if (productoRepetido && productoRepetido.id !== key) {
			productosSelected[productoRepetido.id].cantidad += 1;
			return true;
		}

		return false;
	};

	const removeProductoSelected = (productoKey) => {
		const newProductsSelected = Object.assign({}, productosSelected);
		delete newProductsSelected[productoKey];

		setProductosSelected(newProductsSelected);
	};

	const setEditInfo = () => {
		setProductosSelected({});
		if (isFactura) {
			setFacturaProducts();
		} else {
			setCompraProducts();
		}
		updateTotalSummary();
	};

	const setFacturaProducts = () => {
		notaCredito &&
			notaCredito.productos.forEach((productoNotaCredito) => {
                console.log({productoNotaCredito})
				setProductosSelected((state) => ({
					...state,
					[productoNotaCredito.id]: {
						id: productoNotaCredito.id,
						productoID: productoNotaCredito.producto?.id,
						tipo: "producto",
						nombre: (
							<>
								<span
									className="col-3 pl-1"
									style={{
										minWidth: "100px",
										wordBreak: "break-all !important",
									}}
								>
									{productoNotaCredito.producto?.referencia}
								</span>{" "}
								<span className="col-8">
									{productoNotaCredito.producto?.nombre}
								</span>
							</>
						),
						importe: productoNotaCredito.importe,
						cantidad: productoNotaCredito.cantidad,
						costo: productoNotaCredito.precio_facturado,
						inventariable: productoNotaCredito.producto?.inventariable,
						descuento: productoNotaCredito.descuento,
						precios: [],
						impuesto: {
							value: productoNotaCredito.impuesto?.id,
							label: productoNotaCredito.impuesto?.nombre,
							porcentaje:
								productoNotaCredito.impuesto?.porcentaje,
							tipo: productoNotaCredito?.impuesto?.tipo,
						},
						precioVenta: productoNotaCredito.precio_facturado,
						existencia:
							notaCredito.tipo === "venta"
								? facturaProductosDisponibles.find(
										(prod) =>
											prod.producto.id ===
											productoNotaCredito.producto?.id
								  )?.cantidad || 1
								: facturaProductosDisponibles.find(
										(prod) =>
											prod.id ===
											productoNotaCredito.producto?.id
								  )?.cantidad || 1,
						unidad: productoNotaCredito.producto?.unidad,
						cantidadAnterior: 0,
						precioCompra: 0,
						descuentoCalculado:
							productoNotaCredito.precio_facturado *
							productoNotaCredito.cantidad *
							(productoNotaCredito.descuento / 100),
						impuestoCalculado:
							productoNotaCredito.impuestos *
							productoNotaCredito.cantidad,
					},
				}));
			});
	};
	const setCompraProducts = () => {
		notaCredito &&
			notaCredito.productos.forEach((productoNotaCredito) => {
				setProductosSelected((state) => ({
					...state,
					[productoNotaCredito.id]: {
						id: productoNotaCredito.id,
						productoID:
							productoNotaCredito.producto?.id ||
							productoNotaCredito.item?.id,
						itemID: productoNotaCredito.item?.id || null,
						tipo:
							productoNotaCredito.producto?.tipo ||
							productoNotaCredito.item?.tipo,
						nombre: (
							<>
								<span
									className="col-3 pl-1"
									style={{
										minWidth: "100px",
										wordBreak: "break-all !important",
									}}
								>
									{productoNotaCredito.producto?.referencia ||
										productoNotaCredito.item?.referencia}
								</span>{" "}
								<span className="col-8">
									{productoNotaCredito.producto?.nombre ||
										productoNotaCredito.item?.nombre || productoNotaCredito.nombre_producto}
								</span>
							</>
						),
						importe: productoNotaCredito.importe,
						cantidad: productoNotaCredito.cantidad,
						costo: productoNotaCredito.precio_facturado,
						descuento: productoNotaCredito.descuento,
						precios: [],
						impuesto: {
							value: productoNotaCredito.impuesto?.id,
							label: productoNotaCredito.impuesto?.nombre,
							porcentaje:
								productoNotaCredito.impuesto?.porcentaje,
							tipo: productoNotaCredito?.impuesto?.tipo,
						},
						precioVenta: productoNotaCredito.precio_facturado,
						existencia: facturaProductosDisponibles.find(
                            (prod) =>
                                (prod.item_id ===
                                (productoNotaCredito.producto?.id ||
                                    productoNotaCredito.item?.id))
                                    || (prod.nombre === productoNotaCredito.nombre_producto)
                      )?.cantidad || 1,
						unidad:
							productoNotaCredito.producto?.unidad ||
							productoNotaCredito.item?.unidad,
						cantidadAnterior: 0,
						precioCompra: 0,
						total: productoNotaCredito.total,
						descuentoCalculado:
							productoNotaCredito.precio_facturado *
							productoNotaCredito.cantidad *
							(productoNotaCredito.descuento / 100),
						impuestoCalculado:
							productoNotaCredito.impuestos / productoNotaCredito.cantidad,
					},
				}));
			});
	};

    const updateImpuestos = (impuesto, total) => {
        const st = (total || totalManual) / (((impuesto || impuestoManual)/100) + 1);
	    setSubtotal(st);
	    setTotalImpuestos(estaVencida ? 0 : (total || totalManual) - st);
	};

	const resetFields = () => {
		setProductosSelected({});
		setProductosSelected({
			[Date.now()]: {
				id: Date.now(),
				productoID: null,
				nombre: false,
				cantidad: 1,
				costo: 0,
				descuento: 0,
				precios: [],
				impuesto: null,
				precioVenta: 0,
				existencia: 0,
				unidad: null,
				cantidadAnterior: 0,
				precioCompra: 0,
				descuentoCalculado: 0,
				impuestoCalculado: 0,
			},
		});
		setSumatoriaFinal({
			subtotal: 0,
			total: 0,
			impuestos: {},
			descuento: 0,
		});
	};

	useEffect(() => {
		if(notaCredito?.id && totalManual === 0) {
            const porcentaje = notaCredito?.total_impuestos ? Math.round(notaCredito.total_impuestos / (notaCredito.total - notaCredito.total_impuestos) * 100) : 0;

            setImpuestoManual(porcentaje);
            setTotalManual(notaCredito?.total);
			updateImpuestos(porcentaje, notaCredito?.total);
            setSubtotal(notaCredito?.total - notaCredito?.total_impuestos);
		}
		if (!notaCredito?.id || facturaProductosDisponibles.length < 1) return;
		setEditInfo();
	}, [notaCredito, facturaProductosDisponibles]);

	useEffect(() => {
		updateTotalSummary();
	}, [productosSelected]);

	useEffect(() => {
		if (triggerResetForm) {
			resetFields();
		}
	}, [triggerResetForm]);

    useEffect(() => {
        getOptionsForDropdown("impuestos", 'nombre', 'porcentaje').then(d => {
            setImpuestos(d);
        });
	}, []);

	return (
		<div className="col-md-12 p-0">
			<table className="table d-none d-lg-table">
                <thead>
                    <colgroup>
                        <col width="170px" />
                        <col width="23%"/>
                        <col width="85px"/>
                        <col width="85px"/>
                        <col width="9%"/>
                        <col />
                        <col />
                        <col />
                        <col width="9%" />
                        <col width="5%" />
                        <col width="85px"/>
                    </colgroup>
                    {conDevolucion && (
                        <tr>
                            <th>REF./CÓDIGO</th>
                            <th>Producto</th>
                            <th>Cant.</th>
                            <th>Unidad</th>
                            <th>Importe</th>
                            <th>Impuesto</th>
                            <th>Precio</th>
                            <th>% Desc.</th>
                            <th className="text-right">
                                Total
                            </th>
                            <th className="text-center">
                            </th>
                            <th></th>
                        </tr>
                    )}
                </thead>
				<tbody>
					{conDevolucion && (
						<>
							{Object.values(productosSelected)?.map(
								(productoSelected, index) => {
                                    if (isFactura) {
                                        return (<ProductoFacturaNotaCredito
                                            key={productoSelected.id}
                                            index={index}
                                            productos={facturaProductosDisponibles}
                                            productoSelected={productoSelected}
                                            checkProductoRepetido={
                                                checkProductoRepetido
                                            }
                                            removeProductoSelected={
                                                removeProductoSelected
                                            }
                                            updateTotalSummary={updateTotalSummary}
                                            handleInputChange={handleInputChange}
                                            isEdit={isEdit}
                                            isFactura={isFactura}
											enableProducto={enableProducto}
                                        />);
                                    }
                                    if (!isFactura) {
                                        return (<ProductoCompraNotaCredito
                                            key={productoSelected.id}
                                            index={index}
                                            productos={facturaProductosDisponibles}
                                            productoSelected={productoSelected}
                                            checkProductoRepetido={
                                                checkProductoRepetido
                                            }
                                            removeProductoSelected={
                                                removeProductoSelected
                                            }
                                            updateTotalSummary={updateTotalSummary}
                                            handleInputChange={handleInputChange}
                                            isEdit={isEdit}
                                            isFactura={isFactura}
											enableProducto={enableProducto}
                                        />);
                                    }
                                }
							)}
							<tr>
								<td colSpan="11">
									{form.errors["productos"] && (
										<small className="help-blockParams form-Text">
											{form.errors["productos"][0]}
										</small>
									)}
								</td>
							</tr>
							<tr>
								<td
									colSpan="11"
									style={{ border: 0, paddingTop: 0 }}
									className="text-right"
								>
									<button
										type="button"
										onClick={addProducto}
										className="btn"
									>
										<i className="fas fa-plus" /> Agregar
										Producto
									</button>
								</td>
							</tr>
							<tr>
								<td colSpan="9" className="text-right">
									<strong className="label form-control-label">
										Subtotal:
									</strong>
								</td>
								<td colSpan="2" className="text-right">
									<input
										type="hidden"
										name="subtotal"
										value={sumatoriaFinal.subtotal}
										readOnly
									/>
									{formatCurrency(
										sumatoriaFinal.subtotal
									)}
								</td>
							</tr>
							<tr>
								<td colSpan="9" className="text-right">
									<strong className="label form-control-label">
										Descuento:
									</strong>
								</td>
								<td colSpan="2" className="text-right">
									<input
										type="hidden"
										name="descuentos"
										value={sumatoriaFinal.descuento}
										readOnly
									/>
									-
									{formatCurrency(
										money(sumatoriaFinal.descuento)
									)}
									<input
										type="hidden"
										name="impuestos"
										value={0}
										readOnly
									/>
								</td>
							</tr>
							{Object.keys(sumatoriaFinal.impuestos).map(
								(impuesto, index) => (
									<tr key={index}>
										<td colSpan="9" className="text-right">
											<strong className="label form-control-label">
												{impuesto}
											</strong>
										</td>
										<td colSpan="2" className="text-right">
											{formatCurrency(
                                                sumatoriaFinal.impuestos[
                                                    impuesto
                                                ].toFixed(2)
											)}
										</td>
									</tr>
								)
							)}
						</>
					)}
                    {!conDevolucion && (
                        <>
                            <tr>
                                <td colSpan="9" className="text-right" style={{verticalAlign: 'middle'}}>
                                    Seleccionar Impuesto:
                                </td>
                                <td colSpan="2" style={{verticalAlign: 'middle'}}>
                                    <Select
                                        key="impuesto"
                                        onChange={e => {
                                            setImpuestoManual(e?.value || 0)
                                            updateImpuestos(e?.value, null);
                                        }}
                                        value={impuestoManual && impuestos.filter(i => parseFloat(i.value) === impuestoManual).shift()}
                                        options={impuestos}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="9" className="text-right" style={{verticalAlign: 'middle'}}>
                                    <strong>
                                        Subtotal:
                                    </strong>
                                </td>
                                <td colSpan="2" className="text-right" style={{verticalAlign: 'middle'}}>
                                    {formatCurrency(subtotal)}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="9" className="text-right" style={{verticalAlign: 'middle'}}>
                                    <strong>
                                        Impuesto:
                                    </strong>
                                </td>
                                <td colSpan="2" className="text-right" style={{verticalAlign: 'middle'}}>
                                    {formatCurrency(totalImpuestos)}
                                    <input type="hidden" name="total_impuestos" value={totalImpuestos} />
                                </td>
                            </tr>

                        </>
                    )}
					<tr>
						<td colSpan="9" className="text-right" style={{verticalAlign: 'middle'}}>
							<strong className="label form-control-label">
								Total:
							</strong>
						</td>
						<td colSpan="2" className="text-right" style={{verticalAlign: 'middle'}}>
							{conDevolucion ? (
								<>
									<input
										type="hidden"
										name="total"
										value={sumatoriaFinal.total}
										readOnly
									/>
									{formatCurrency(
										sumatoriaFinal.total
									)}
								</>
							) : (
								<input
									className="form-control"
									type="number"
									name="total"
									value={totalManual}
									onChange={(e) => {
										setTotalManual(e.target.value);
                                        updateImpuestos(null, e.target.value);
                                    }}
								/>
							)}
						</td>
					</tr>
                    {form.errors.total && (
                        <tr>
                            <td colSpan="11" className="text-right">
                                <small className="help-blockParams form-Text">
                                    {form.errors.total[0]}
                                </small>
                            </td>
                        </tr>
                    )}
				</tbody>
			</table>
			<hr />
		</div>
	);
};

export default ProductosContainer;
